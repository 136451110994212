/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import {
  simpleReducer,
  unidentifiedCategory,
  categoryMapper,
  getDefaultDateRangeForCharts,
} from "utils";
import { useRequest, useSelection } from "utils/hooks";
import { Spacer } from "./styled/ui";
import { TransfersToolbar, TimeSeriesPanel, TopNamedCounterparties } from ".";
import { LoadingContextProvider } from "./providers/LoadingContextProvider";

const [defaultStartDate, defaultEndDate] = getDefaultDateRangeForCharts();

const TransferDataPanel = ({
  asset,
  address,
  assetOptions,
  profileData,
  navigationId,
  externalToolbar,
}) => {
  const [{ data, isLoading }] = useRequest(
    "/api/ix/private/clusters/categories",
    Boolean(externalToolbar?.filterCategories) === false
  );
  const {
    selection: { selectedAlert },
    resetSelections,
  } = useSelection();

  const initState = {
    filterAsset: asset,
    filterAddress: address,
    filterStartDate: defaultStartDate,
    filterEndDate: defaultEndDate,
    filterCategories: null,
    filterOverride: false,
    filterSelectedAlert: null,
    resolution: "WEEK",
  };
  const [
    {
      filterAsset,
      filterAddress,
      filterStartDate,
      filterEndDate,
      filterCategories,
      filterOverride,
      filterSelectedAlert,
      resolution,
    },
    setFilter,
  ] = React.useReducer(simpleReducer, initState);

  const serviceName = profileData ? profileData.data.systemName : "N/A";
  const allCategoriesLength = data && data.length ? data.length : -1;
  const isBTCService =
    assetOptions.data && assetOptions.data.some((a) => a.asset === "BTC");
  const mappedCategories =
    data && data.length > 0
      ? [unidentifiedCategory, ...data].map(categoryMapper)
      : [];
  const defaultCategories = new Set(
    mappedCategories.filter((c) => c.isRisky !== null).map((c) => c.value)
  );

  // Manage selected alert
  if (selectedAlert && !Object.is(selectedAlert, filterSelectedAlert)) {
    const {
      asset: alertAsset,
      category,
      weekstartdate,
      weekenddate,
    } = selectedAlert;
    setFilter({
      filterSelectedAlert: selectedAlert,
      filterAsset: alertAsset,
      filterCategories: new Set([category]),
      filterStartDate: Date.parse(`${weekstartdate}T00:00:00Z`) / 1000,
      filterEndDate: Date.parse(`${weekenddate}T00:00:00Z`) / 1000,
      resolution: "DAY",
      filterOverride: true,
    });
  } else if (!selectedAlert && filterOverride) {
    if (resetSelections) {
      setFilter(initState);
    } else {
      setFilter({
        filterSelectedAlert: null,
        filterOverride: false,
      });
    }
  }
  // :END Manage selected alert

  // In case of external toolbar our categories can only be still loading if their length is zero
  const isLoadingState = externalToolbar ? false : isLoading;

  return (
    <LoadingContextProvider isLoading={isLoadingState}>
      {externalToolbar !== false || (
        <TransfersToolbar
          asset={filterAsset}
          startDate={filterStartDate}
          endDate={filterEndDate}
          categories={filterCategories || defaultCategories}
          assetOptions={assetOptions}
          serviceName={serviceName}
          categoryOptions={mappedCategories}
          filterOverride={filterOverride}
          onFilterUpdate={(field) => setFilter(field)}
          navigationId={navigationId}
        />
      )}
      <Spacer spaceDirection="vertical" size="large" />
      <TimeSeriesPanel
        asset={externalToolbar?.filterAsset || filterAsset}
        address={externalToolbar?.filterAddress || filterAddress}
        startDate={externalToolbar?.filterStartDate || filterStartDate}
        endDate={externalToolbar?.filterEndDate || filterEndDate}
        categories={
          externalToolbar?.filterCategories ||
          filterCategories ||
          defaultCategories
        }
        resolution={resolution}
        allCategories={
          externalToolbar?.allCategories ||
          (filterCategories && filterCategories.size >= allCategoriesLength)
        }
        serviceName={serviceName}
        isBTCService={isBTCService}
        fixedChartType={externalToolbar?.chartType}
        onFilterUpdate={(field) => setFilter(field)}
      />
      <TopNamedCounterparties
        asset={externalToolbar?.filterAsset || filterAsset}
        address={externalToolbar?.filterAddress || filterAddress}
        startDate={externalToolbar?.filterStartDate || filterStartDate}
        endDate={externalToolbar?.filterEndDate || filterEndDate}
        categories={
          externalToolbar?.filterCategories ||
          filterCategories ||
          defaultCategories
        }
        serviceName={serviceName}
      />
    </LoadingContextProvider>
  );
};

TransferDataPanel.propTypes = {
  asset: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  assetOptions: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.any,
  }).isRequired,
  profileData: PropTypes.objectOf(PropTypes.any).isRequired,
  navigationId: PropTypes.string,
  externalToolbar: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
};

TransferDataPanel.defaultProps = {
  navigationId: undefined,
  externalToolbar: false,
};

export default TransferDataPanel;
