/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Icon,
  Row,
  Col,
  Typography,
  List,
  Card,
  Loader,
  ErrorBoundary,
  Tooltip,
} from "@chainalysis/react-ui";
import { jsx } from "@emotion/core";
import { useRequest, useBasicAccount } from "utils/hooks";
import { getCategoryColor, getCategoryText } from "utils";
import { SearchSelect } from ".";
import { FullWidthContainer, DashboardHero } from "./styled";
import { Spacer, CategoryDot, BrowseLink } from "./styled/ui";
import { CategoryHeader } from "./styled/text";

const panelHavigationButtonStyle = {
  float: "right",
  color: "#64707d",
  fontWeight: 500,
};
const tooltipShowLengthThreshold = 35;

const CategoryList = ({ loading, categories, onCategorySelect }) => {
  if (loading) {
    return <Loader description="Loading Categories..." />;
  }

  return (
    <List
      grid={{ gutter: 16, xs: 2, sm: 2, md: 3, lg: 4, xl: 4, xxl: 4 }}
      dataSource={categories}
      renderItem={(category) => (
        <List.Item>
          <Card
            css={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => onCategorySelect(category)}
          >
            <CategoryDot color={getCategoryColor(category)} />
            <Spacer spaceDirection="vertical" size="small" />
            <Card.Meta
              title={
                <CategoryHeader>{getCategoryText(category)}</CategoryHeader>
              }
            />
          </Card>
        </List.Item>
      )}
    />
  );
};

CategoryList.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const ServiceList = ({ category }) => {
  const [{ data, isLoading }] = useRequest(
    `/api/ix/private/clusters/${category}/services`
  );

  if (isLoading) {
    return <Loader description="Loading Services..." />;
  }

  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6 }}
      size="large"
      itemLayout="vertical"
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          css={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {item.name.length > tooltipShowLengthThreshold ? (
            <Tooltip title={item.name}>
              <Link to={`/services/${item.asset}/${item.address}`}>
                {item.name}
              </Link>
            </Tooltip>
          ) : (
            <Link to={`/services/${item.asset}/${item.address}`}>
              {item.name}
            </Link>
          )}
        </List.Item>
      )}
    />
  );
};

ServiceList.propTypes = { category: PropTypes.string.isRequired };

const SelectionPanelHeader = ({ category, onClose, onBack }) => (
  <Row type="flex" justify="center">
    <Col span={12}>
      <Typography.Text css={{ fontSize: "20px", color: "#293972" }}>
        <Icon type="appstore" />{" "}
        {getCategoryText(category) || "Browse by Category"}
      </Typography.Text>
    </Col>
    <Col span={12}>
      {onClose && (
        <Button type="link" css={panelHavigationButtonStyle} onClick={onClose}>
          CLOSE
        </Button>
      )}
      {category && (
        <Button type="link" css={panelHavigationButtonStyle} onClick={onBack}>
          GO BACK
        </Button>
      )}
    </Col>
  </Row>
);

SelectionPanelHeader.defaultProps = { category: null };

SelectionPanelHeader.propTypes = {
  category: PropTypes.string,
  onClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  onBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
};

const SelectionPanel = ({ open, onClose }) => {
  const [category, setCategory] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  const [{ data, isLoading, skip, isError }] = useRequest(
    "/api/ix/private/clusters/categories",
    !categories
  );

  const categoriesLoaded = !(isLoading || skip || categories || isError);
  const isClearSelection = category && !open;

  if (categoriesLoaded) {
    setCategories(data);
  }

  if (isClearSelection) {
    setCategory(null);
  }

  return (
    <ErrorBoundary>
      <Spacer spaceDirection="vertical" size="large" />
      <Row css={{ minHeight: 300 }} hidden={!open} type="flex" justify="center">
        <Col span={24}>
          <SelectionPanelHeader
            category={category}
            onClose={onClose}
            onBack={() => setCategory(null)}
          />
          <Spacer spaceDirection="vertical" size="small" />
          {!category && (
            <CategoryList
              loading={!categories}
              categories={categories}
              onCategorySelect={setCategory}
            />
          )}
          {category && <ServiceList category={category} />}
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

SelectionPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
};

const DashboardSearchPanel = () => {
  const isBasicAccount = useBasicAccount();
  const [open, setOpen] = React.useState(isBasicAccount);
  const togglePanel = () => isBasicAccount || setOpen(!open);

  const dashboardHeroStyle = {};
  const dashboardHeroInnerDivStyle = {};

  return (
    <>
      <FullWidthContainer css={{ marginTop: "-1rem" }}>
        <DashboardHero style={dashboardHeroStyle}>
          <div css={{ margin: "auto" }} style={dashboardHeroInnerDivStyle}>
            <SearchSelect placeholder="Search for any service" size="large" />
            {isBasicAccount || (
              <div>
                <BrowseLink onClick={togglePanel}>
                  <Icon type="appstore" /> Or browse by category
                </BrowseLink>
              </div>
            )}
          </div>
        </DashboardHero>
      </FullWidthContainer>
      <SelectionPanel
        open={open}
        onClose={isBasicAccount ? false : togglePanel}
      />
    </>
  );
};

export default DashboardSearchPanel;
