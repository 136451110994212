/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import { Menu, Icon, Layout, Divider, Row, Col } from "@chainalysis/react-ui";
import { Link, withRouter } from "react-router-dom";

import { jsx, css } from "@emotion/core";
import { mq } from "utils";
import { signOut } from "utils/request";
import { useBasicAccount } from "utils/hooks";
import logo from "img/logo.svg";
import { SearchSelect } from ".";

const { Header } = Layout;

// The Ant Design menu component does not accept other ant design components or react elements as children
// Create component wrappers to work around this limitation
const VerticalMenuDivider = () => (
  <Divider
    css={css`
      ${mq("large")} {
        display: none;
      }
    `}
    type="vertical"
  />
);

const MenuLogo = () => (
  <Link to="/services" css={{ width: "52px", height: "62px" }}>
    <img
      src={logo}
      css={{ height: "32px", marginRight: "20px" }}
      alt="Chainalysis"
    />
  </Link>
);

const Nav = ({ location: { pathname }, fullName }) => {
  const isBasicAccount = useBasicAccount();
  return (
    <Header
      theme="light"
      css={{
        background: "#fff",
        padding: 0,
        border: "solid rgba(34,36,38,.15)",
        borderWidth: "0 0 1px",
      }}
    >
      <Row>
        <Col span={22} offset={1}>
          <Menu
            selectedKeys={[/\/[^/]*/.exec(pathname)[0]]}
            theme="light"
            css={{
              lineHeight: "62px !important",
              border: 0,
              display: "flex",
              alignItems: "center",
            }}
            mode="horizontal"
          >
            <MenuLogo />
            <Menu.Item key="/services">
              <Link to="/services">Services</Link>
            </Menu.Item>
            {isBasicAccount || (
              <>
                <VerticalMenuDivider />
                <Menu.Item key="/alerts">
                  <Link to="/alerts">Alerts</Link>
                </Menu.Item>
              </>
            )}
            <span
              css={css`
                padding: 0 20px;
                margin-left: auto;
              `}
            >
              {pathname !== "/services" && <SearchSelect />}
            </span>
            <Divider className="lg-hide" type="vertical" />
            <Menu.SubMenu
              title={
                <span>
                  <Icon type="user" />
                  Account
                </span>
              }
            >
              <Menu.ItemGroup title={fullName || "Account"}>
                <Menu.Divider />
                {isBasicAccount || (
                  <Menu.Item key="/risk-score-methodology">
                    <Link to="/risk-score-methodology">
                      <Icon type="read" />
                      Risk Score Methodology
                    </Link>
                  </Menu.Item>
                )}

                <Menu.Item key="setting:3" onClick={signOut}>
                  <Icon type="logout" />
                  Sign out
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu.SubMenu>
          </Menu>
        </Col>
      </Row>
    </Header>
  );
};

Nav.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  fullName: PropTypes.string.isRequired,
};

export default withRouter(Nav);
