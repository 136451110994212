/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import { jsx, css } from "@emotion/core";
import { Link, withRouter } from "react-router-dom";
import {
  getCategoryText,
  simpleReducer,
  unidentifiedCategory,
  categoryMapper,
  getChartConfig,
  getDefaultDateRangeForCharts,
} from "utils";
import {
  PageHeader,
  Panel,
  Tooltip,
  Icon,
  Row,
  Col,
  Affix,
  Skeleton,
} from "@chainalysis/react-ui";
import {
  ServiceName,
  OrgName,
  CategoryName,
  SectionHeader,
  PanelHeader,
  InfoIcon,
} from "./styled/text";
import { Spacer, CategoryDot } from "./styled/ui";
import {
  BenchmarkSeriesChart,
  TransferDataPanel,
  TransfersToolbar,
  ServiceAlertsTable,
} from ".";
import { ChartDataProvider } from "./providers";

const scoreColorMap = {
  "High Risk": "#ED5252",
  High: "#ED5252",
  "Medium Risk": "#F4B12F",
  Medium: "#F4B12F",
  "Low Risk": "#3B990D",
  Low: "#3B990D",
};

const notAvailable = "—";

const iconCss = css`
  color: #bfbfbf;
  :hover {
    color: #293972;
  }
`;

function absolutizeUrl(url) {
  if (!/^http[s]*:\/\/[\w]+/i.test(url)) {
    return `//${url}`;
  }
  return url;
}

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
  );
}

function renderRoutes(profile) {
  const routes = [{ path: "/", breadcrumbName: "Services" }];
  if (profile.systemName || profile.name) {
    routes.push({ breadcrumbName: profile.systemName || profile.name });
  }
  return routes;
}

const ServiceNameHeader = ({ internalName, orgName }) => {
  if (internalName && orgName) {
    return (
      <>
        <ServiceName data-test-id="name">{internalName}</ServiceName>
        <Spacer spaceDirection="vertical" size="small" />
        <OrgName small data-test-id="orgName">
          {orgName}
        </OrgName>
        <Tooltip
          title="The service has been named by your organization."
          placement="topRight"
          arrowPointAtCenter
        >
          <Icon type="info-circle" theme="filled" css={iconCss} />
        </Tooltip>
      </>
    );
  }
  if (internalName) {
    return <ServiceName data-test-id="name">{internalName}</ServiceName>;
  }
  if (orgName) {
    return (
      <>
        <OrgName>{orgName}</OrgName>
        <Tooltip
          title="This service has been named by your organization but not yet confirmed by Chainalysis."
          placement="topRight"
          arrowPointAtCenter
        >
          <Icon type="info-circle" theme="filled" css={iconCss} />
        </Tooltip>
      </>
    );
  }
  return null;
};

ServiceNameHeader.propTypes = {
  internalName: PropTypes.string,
  orgName: PropTypes.string,
};

ServiceNameHeader.defaultProps = {
  internalName: undefined,
  orgName: undefined,
};

const defaultEndDate = Math.floor(Date.now() / 1000);
const leapDay = new Date(new Date().getFullYear(), 1, 29) === 1 ? 1 : 0;
const defaultStartDate = Math.floor(
  defaultEndDate - (365 * 1 + leapDay) * 86400
);

const BasicProfileOverviewPanel = ({
  profileData,
  asset,
  address,
  assetOptions,
  categoryOptions,
  enableRiskScore,
}) => {
  const { data, loading } = profileData;
  const serviceName = profileData ? profileData.data.systemName : "N/A";
  const allCategoriesLength =
    categoryOptions.data && categoryOptions.data.length
      ? categoryOptions.data.length
      : -1;
  const isBTCService =
    assetOptions.data && assetOptions.data.some((a) => a.asset === "BTC");
  const mappedCategories = (
    allCategoriesLength > -1
      ? [unidentifiedCategory, ...categoryOptions.data].map(categoryMapper)
      : []
  ).filter((c) => c.value !== "stolen ether");
  const defaultCategories = new Set(
    mappedCategories.filter((c) => c.isRisky !== null).map((c) => c.value)
  );

  const initState = {
    filterAsset: asset,
    filterAddress: address,
    filterStartDate: defaultStartDate,
    filterEndDate: defaultEndDate,
    filterCategories: null,
  };
  const [
    {
      filterAsset,
      filterAddress,
      filterStartDate,
      filterEndDate,
      filterCategories,
    },
    setFilter,
  ] = React.useReducer(simpleReducer, initState);

  const defaultDateRange = getDefaultDateRangeForCharts();

  const allCategories = Boolean(
    filterCategories && filterCategories.size >= allCategoriesLength
  );

  const adjustedStartDate = filterStartDate || defaultDateRange[0];
  const adjustedEndDate = filterEndDate || defaultDateRange[1];

  const benchmarkChartConfig = getChartConfig("benchmark", {
    asset: filterAsset,
    address: filterAddress,
    resolution: "WEEK",
    startDate: adjustedStartDate,
    endDate: adjustedEndDate,
    serviceName,
    allCategories,
    categories: filterCategories || defaultCategories,
  });

  return (
    <PageHeader
      style={{ padding: 0 }}
      breadcrumb={{
        itemRender,
        routes: renderRoutes(data),
      }}
    >
      <Affix>
        <Panel isLoading={loading}>
          <Row
            type="flex"
            justify="space-between"
            gutter={[16, 16]}
            css={{ alignItems: "flex-start" }}
          >
            <Col>
              <ServiceNameHeader
                internalName={data.systemName || data.name}
                orgName={data.orgName}
              />
            </Col>
            <Col>
              {data?.metadata?.category && (
                <CategoryName data-test-id="category">
                  <Icon type="appstore" />{" "}
                  {getCategoryText(data.metadata.category?.toLowerCase())}
                </CategoryName>
              )}
              {data?.metadata?.url && (
                <div>
                  <a
                    href={absolutizeUrl(data.metadata.url)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.metadata.url}
                  </a>
                </div>
              )}
            </Col>
            {enableRiskScore && (
              <Col>
                {data?.score ? (
                  <div css={{ paddingTop: "20%" }}>
                    <CategoryDot
                      style={{ display: "inline-block" }}
                      color={scoreColorMap[data.score]}
                    />
                    <Spacer spaceDirection="horizontal" size="small" />
                    {data.score}
                  </div>
                ) : (
                  notAvailable
                )}
              </Col>
            )}
            <Col>
              <TransfersToolbar
                asset={filterAsset}
                startDate={filterStartDate}
                endDate={filterEndDate}
                categories={filterCategories || defaultCategories}
                assetOptions={assetOptions}
                serviceName={serviceName}
                categoryOptions={mappedCategories}
                filterOverride={false}
                onFilterUpdate={(field) => setFilter(field)}
                navigationId="transferData"
                headerOnly
              />
            </Col>
          </Row>
        </Panel>
      </Affix>
      <Spacer spaceDirection="vertical" size="large" />
      <ServiceAlertsTable
        asset={asset}
        address={address}
        profileData={profileData}
        navigationId="alerts"
      />
      <Spacer spaceDirection="vertical" size="large" />
      <SectionHeader type="area-chart" text="Transfer Data" />
      <Spacer spaceDirection="vertical" size="large" />
      <TransferDataPanel
        asset={filterAsset}
        address={filterAddress}
        assetOptions={assetOptions}
        profileData={profileData}
        navigationId="transferData"
        externalToolbar={{
          filterAsset,
          filterAddress,
          filterCategories: filterCategories || defaultCategories,
          filterStartDate,
          filterEndDate,
          allCategories,
          chartType: "topVolume",
        }}
      />
      <Spacer spaceDirection="vertical" size="large" />
      <SectionHeader type="line-chart" text="Benchmarking" />
      <Spacer spaceDirection="vertical" size="large" />
      <Skeleton
        paragraph={{ rows: 4, cols: 5 }}
        loading={loading && allCategoriesLength >= 0}
        title="Benchmark against indices"
        active
      >
        <Panel
          title={
            <>
              <PanelHeader>Benchmark against indices</PanelHeader>
              <Spacer spaceDirection="horizontal" size="small" />
              <Tooltip
                placement="topRight"
                arrowPointAtCenter
                title={benchmarkChartConfig.tooltip}
              >
                <InfoIcon active />
              </Tooltip>
            </>
          }
        >
          <ChartDataProvider chartConfig={benchmarkChartConfig}>
            {({ chartData }) => (
              <BenchmarkSeriesChart
                isBTCService={isBTCService}
                wideChart
                noDataMessage="No transfer data is available for your selected filters."
                serviceName={serviceName}
                data={chartData}
                allSelected={allCategories}
                selected={filterCategories || defaultCategories}
              />
            )}
          </ChartDataProvider>
        </Panel>
      </Skeleton>
    </PageHeader>
  );
};

BasicProfileOverviewPanel.propTypes = {
  asset: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  assetOptions: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.any,
  }).isRequired,
  categoryOptions: PropTypes.shape({
    isLoading: PropTypes.bool,
    data: PropTypes.any,
  }).isRequired,
  profileData: PropTypes.objectOf(PropTypes.any).isRequired,
  enableRiskScore: PropTypes.bool.isRequired,
};

export default withRouter(BasicProfileOverviewPanel);
