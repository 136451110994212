import { useEffect, useReducer } from 'react';
import apiRequest from '../request';

const Actions = {
  init: 'init',
  success: 'success',
  fail: 'fail',
  skip: 'skip',
};

const initState = { data: null, isError: false, skip: false, isLoading: true };

const responseReducer = (state, action) => {
  switch (action.type) {
    case Actions.init:
      return initState;
    case Actions.success:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case Actions.fail:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case Actions.skip:
      return {
        ...state,
        skip: true,
      };
    default:
      return initState;
  }
};

const useRequest = (url, execute = true) => {
  const [results, dispatch] = useReducer(responseReducer, initState);

  useEffect(() => {
    const fetchData = async () => {
      if (!execute) {
        dispatch({ type: Actions.skip });
      } else {
        dispatch({ type: Actions.init });
        try {
          const result = await apiRequest(url);
          dispatch({ type: Actions.success, payload: result.data });
        } catch (error) {
          dispatch({ type: Actions.fail });
        }
      }
    };
    fetchData();
  }, [url, execute]);

  return [{ ...results }];
};
export default useRequest;
