import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Loader } from '@chainalysis/react-ui';

const PrivateRoute = ({ component: Component, isAuth, isAgreementSigned, hasKryptosAccess, isFirstRender, fullName, location, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isFirstRender) {
        return <Loader container />
      }

      if (!isAuth) {
        if (process.env.REACT_APP_LOGIN_URL) {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
          return null;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }

      if (!hasKryptosAccess) {
        return <Redirect
          to={{
            pathname: '/access-denied',
          }}
        />
      }

      if (!isAgreementSigned) {
        return (
          <Redirect
            to={{
              pathname: '/agreement',
            }}
          />
        )
      }

      return(
        <Component {...props} fullName={fullName} />
      )
    }}
  />
);

PrivateRoute.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  isAgreementSigned: PropTypes.bool.isRequired,
  hasKryptosAccess: PropTypes.bool.isRequired,
  isFirstRender: PropTypes.bool.isRequired,
  component: PropTypes.func,
  fullName: PropTypes.string,
  location: ReactRouterPropTypes.location,
};

PrivateRoute.defaultProps = { component: undefined, location: undefined, fullName: undefined };

export default PrivateRoute;
