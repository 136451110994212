import React from 'react';
import { GlobalContext } from '../../components/providers/GlobalContextProvider';

const useCapabilities = () => {
  const { capabilities } = React.useContext(GlobalContext);
  const hasCapability = flag => capabilities.includes(flag);
  return hasCapability;
};

const useBasicAccount = () => {
  const { isBasicAccount } = React.useContext(GlobalContext);
  return isBasicAccount;
};

const useUserInfo = () => {
  const { fullName, email, orgName } = React.useContext(GlobalContext);
  return {
    fullName,
    email,
    orgName,
  };
};

export default useCapabilities;
export { useUserInfo, useBasicAccount };
