/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { withRouter } from "react-router-dom";
import { AutoComplete, Icon, Input } from "@chainalysis/react-ui";
import { jsx } from "@emotion/core";
import request from "../utils/request";

const { Option, OptGroup } = AutoComplete;

const searchResultItem = (result) => (
  <Option key={result.key}>{result.label}</Option>
);

class SearchSelect extends React.PureComponent {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.string,
  };

  constructor() {
    super();
    this.searchService = debounce(this.searchService, 400);
  }

  state = {
    isLoading: false,
    results: [],
    value: "",
    isError: false,
    hashCount: 0,
  };

  searchService = () =>
    request({
      method: "GET",
      url: `/api/ix/private/search/?q=${this.state.value}`,
    })
      .then((response) =>
        this.setState({
          isLoading: false,
          isError: false,
          results:
            response.data &&
            response.data.map((data) => ({
              label: data.systemName || data.orgName,
              key: `${data.rootAsset}/${data.rootAddress}`,
            })),
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
          isError: true,
        })
      );

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" });

  handleResultSelect = (result) => {
    if (!this.state.results && !this.state.results.length) {
      return;
    }
    this.props.history.push(`/services/${result}`);
    this.setState({ value: "", results: [], hashCount: 0 });
  };

  handleSearchChange = (value) => {
    if (value.length < 1) {
      return this.resetComponent();
    }
    return this.setState({ isLoading: value.length > 2, value }, () =>
      value.length > 2 ? this.searchService() : undefined
    );
  };

  render() {
    const { placeholder = "Search for a service", size = "default" } =
      this.props;
    const { isLoading, results, value, isError } = this.state;
    const serviceResults =
      results.length !== 0 && results.map(searchResultItem);
    const noServiceResults = [
      <Option key={1} disabled>
        No results found
      </Option>,
    ];
    const children = isError
      ? [<Option key={1}>Search is unavailable</Option>]
      : isLoading
      ? []
      : value.length > 2
      ? [
          <OptGroup label="Services">
            {serviceResults || noServiceResults}
          </OptGroup>,
        ]
      : [];
    const inputCSS =
      size === "default"
        ? { "@media (min-width: 991px)": { minWidth: 325 } }
        : { minWidth: 450 };
    return (
      <AutoComplete
        size={size}
        loading={isLoading}
        onSelect={
          !isLoading
            ? (selected) => this.handleResultSelect(selected)
            : undefined
        }
        onSearch={this.handleSearchChange}
        dataSource={children}
        value={value}
        optionLabelProp="value"
        dropdownStyle={{ width: 300 }}
      >
        <Input
          css={inputCSS}
          size={size}
          placeholder={placeholder}
          prefix={<Icon type={isLoading ? "loading" : "search"} />}
        />
      </AutoComplete>
    );
  }
}

export default withRouter(SearchSelect);
