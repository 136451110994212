/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import { withRouter } from "react-router-dom";
import { Tooltip, Icon } from "@chainalysis/react-ui";
import PropTypes from "prop-types";
import RouterPropTypes from "react-router-prop-types";
import { jsx } from "@emotion/core";
import { xSmallPageLimit } from "utils/constants";
import { useSelection } from "utils/hooks";
import {
  topCounterpartiesHeaderMap,
  topCounterpartiesDataMap,
  transfersHeaderMap,
  transfersDataMap,
} from "utils/table";
import { DynamicTable, CardList } from ".";
import { SimpleDataProvider } from "./providers";
import { PanelHeader, InfoIcon } from "./styled/text";
import { Spacer } from "./styled/ui";
import { LoadingContext } from "./providers/LoadingContextProvider";

const TopNamedCounterparties = ({
  asset,
  address,
  startDate,
  endDate,
  categories,
  serviceName,
  history,
}) => {
  const { isLoading } = React.useContext(LoadingContext);
  const {
    selection: { selectedAlert },
  } = useSelection();
  const [expandedKey, setExpandedKey] = React.useState([]);

  let [startTimestamp, endTimestamp] = [null, null];

  if (selectedAlert) {
    const { weekstartdate, weekenddate } = selectedAlert;
    [startTimestamp, endTimestamp] = [weekstartdate, weekenddate].map(
      (date) => Date.parse(date) / 1000
    );
  }

  const expandable = {
    // eslint-disable-next-line react/prop-types
    expandIcon: ({ expanded }) =>
      expanded ? <Icon type="up" /> : <Icon type="down" />,
    expandedRowKeys: expandedKey,
    expandRowByClick: true,
    onExpand: (expanded, record) => {
      setExpandedKey(expanded ? [record.name] : []);
    },
    expandedRowRender: (record, index, indent, expanded) => {
      const { rootAsset, rootAddress } = record;
      return expanded ? (
        <SimpleDataProvider
          pageLimit={xSmallPageLimit}
          resourceName={`clusters/${asset}/${address}/${rootAsset}/${rootAddress}/transfers?startDate=${startTimestamp}&endDate=${endTimestamp}`}
          name="transfers"
          defaultSort="percentage"
          defaultSortDirection="desc"
          isLoading={isLoading || categories.size === 0}
        >
          {(transfers) => (
            <CardList
              title="Transfers"
              rowKey={["hash", "receivingAddress"]}
              displayCount
              headers={Object.keys(transfersHeaderMap)}
              columnHeaderMap={transfersHeaderMap}
              dataMap={transfersDataMap}
              {...transfers}
            />
          )}
        </SimpleDataProvider>
      ) : null;
    },
  };

  return (
    <SimpleDataProvider
      pageLimit={xSmallPageLimit}
      // eslint-disable-next-line max-len
      resourceName={`clusters/${asset}/${address}/counterparty/top-by-volume?categories=${Array.from(
        categories || []
      ).join(",")}${startDate ? `&startDate=${startDate}` : ""}${
        endDate ? `&endDate=${endDate - 1}` : ""
      }`}
      name="counterparties"
      defaultSort="percentage"
      defaultSortDirection="desc"
      isLoading={isLoading || categories.size === 0}
    >
      {(services) => (
        <DynamicTable
          isTabbedPanel
          displayOnlyTable
          tableHeader={
            <div css={{ display: "flex", alignItems: "center" }}>
              <PanelHeader>Top Counterparties</PanelHeader>
              <Spacer spaceDirection="horizontal" size="small" />
              <Tooltip
                arrowPointAtCenter
                overlayStyle={{ maxWidth: "500px" }}
                placement="right"
                title={`The largest counterparties by volume for ${serviceName}.`}
              >
                <InfoIcon active />
              </Tooltip>
            </div>
          }
          expandable={selectedAlert && expandable}
          handleRowClick={selectedAlert ? () => {} : undefined}
          dataMap={topCounterpartiesDataMap(history)}
          columnHeaderMap={topCounterpartiesHeaderMap}
          pageLimit={xSmallPageLimit}
          headerCells={[
            "name",
            "category",
            "percentage",
            "sum",
            "sent",
            "received",
          ]}
          rowKey="name"
          hidePagination
          emptyText="There is no data for either the categories, asset, or date range selected."
          {...services}
        />
      )}
    </SimpleDataProvider>
  );
};

TopNamedCounterparties.propTypes = {
  asset: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  categories: PropTypes.instanceOf(Set),
  serviceName: PropTypes.string,
  history: RouterPropTypes.history.isRequired,
};

TopNamedCounterparties.defaultProps = {
  startDate: null,
  endDate: null,
  categories: null,
  serviceName: "N/A",
};

export default withRouter(TopNamedCounterparties);
