import React from 'react';
import PropTypes from 'prop-types';

const LoadingContext = React.createContext({
  isLoading: false,
  updateLoading: () => {},
});

const LoadingContextProvider = ({ isLoading, children }) => (
  <LoadingContext.Provider value={{ isLoading }}>{children}</LoadingContext.Provider>
);

LoadingContextProvider.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export {
  LoadingContext,
  LoadingContextProvider,
};
