import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ErrorBoundary, List, Card, Tooltip, Loader, Icon } from '@chainalysis/react-ui';
import { getCategoryColor, getCategoryText, getCategoryDesc, excludedCategories } from 'utils';
import { useRequest } from 'utils/hooks';
import { CategoryDot, Spacer } from './styled/ui';
import { TableHeader, CategoryHeader } from './styled/text';

const tooltipShowLengthThreshold = 35;

const ServiceList = ({ category }) => {
  const [{ data, isLoading }] = useRequest(`/api/ix/private/clusters/${category}/services`);

  if (isLoading) {
    return <Loader description="Loading Services..." />;
  }

  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6 }}
      size="large"
      itemLayout="vertical"
      dataSource={data}
      renderItem={item => (
        <List.Item css={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {item.name.length > tooltipShowLengthThreshold
            ? (
              <Tooltip title={item.name}>
                <Link to={`/services/${item.asset}/${item.address}`}>{item.name}</Link>
              </Tooltip>
            )
            : <Link to={`/services/${item.asset}/${item.address}`}>{item.name}</Link>
            }
        </List.Item>
      )}
    />
  );
};

ServiceList.propTypes = { category: PropTypes.string.isRequired };

const ExpandableCategoryList = () => {
  const [expandedCategory, setExpandedCategory] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  const [{ data, isLoading, skip, isError }] = useRequest('/api/ix/private/clusters/categories', !categories);
  const categoriesLoaded = !(isLoading || skip || categories || isError);

  const isCategoryExpanded = category => expandedCategory === category;

  if (categoriesLoaded) {
    setCategories(data.filter(c => excludedCategories.includes(c) === false));
  }

  return categories && (
    <ErrorBoundary>
      <List
        itemLayout="horizontal"
        dataSource={categories}
        bordered={false}
        split={false}
        renderItem={category => (
          <List.Item>
            <Card style={{ width: '100%' }} onClick={() => setExpandedCategory(isCategoryExpanded(category) ? null : category)}>
              <Card.Meta
                title={
                  <>
                    <CategoryHeader>
                      <CategoryDot style={{ display: 'inline-block' }} color={getCategoryColor(category)} />
                      <Spacer spaceDirection="horizontal" size="small" />
                      {getCategoryText(category)}
                    </CategoryHeader>
                    <Icon
                      style={{ float: 'right' }}
                      type={isCategoryExpanded(category) ? 'up' : 'down'}
                      onClick={() => setExpandedCategory(isCategoryExpanded(category) ? null : category)}
                    />
                  </>
                    }
                description={
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: getCategoryDesc(category) }} />
                    <Spacer spaceDirection="vertical" size="large" />
                    {isCategoryExpanded(category) && (<ServiceList category={category} />)}
                  </div>
                }
              >
              </Card.Meta>
            </Card>
          </List.Item>
        )}
      />
    </ErrorBoundary>
  );
};


export default ExpandableCategoryList;
