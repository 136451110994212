// This component provides tabular data. Use this provider when data is indefinite but not large.
// All actions require api requests, but state is not saved in the URL, and the frontend does not cache.

import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import request from '../../utils/request';

// When alert status is updated, re-render the table with the new status
const updateTableWithAlertStatus = (data, updated) => {
  const tableData = data;
  tableData[tableData.findIndex(row => row.alertIdentifier === updated[0].alertIdentifier)].alertStatus = updated[0].alertStatus;
  return tableData;
};

class IntermediateDataProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    defaultSort: PropTypes.string.isRequired,
    defaultFilter: PropTypes.objectOf(PropTypes.string.isRequired),
    resourceName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pageLimit: PropTypes.number.isRequired,
    preservedFilter: PropTypes.string,
  };

  static defaultProps = { defaultFilter: {}, preservedFilter: undefined };

  state = {
    apiData: {},
    count: undefined,
    displayRefresh: false,
    isTableLoading: true,
    filters: { ...this.props.defaultFilter },
    sortDirection: 'desc',
    sortColumn: this.props.defaultSort,
    offset: 0,
    expandedRowKey: [],
    isAlertsLoading: false,
    updateAlertStatusError: false,
    patchedAlert: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { resourceName, pageLimit } = this.props;
    const { filters, sortColumn, sortDirection, offset } = this.state;
    this.setState({ isTableLoading: true, expandedRowKey: [], displayRefresh: false });
    const paramString = queryString.stringify({
      ...filters,
      offset,
      ...{ sort: sortDirection === 'desc' ? `${sortColumn} desc` : sortColumn },
      limit: pageLimit,
    });
    const formattedParamString = resourceName.includes('?') ? `&${paramString}` : `/?${paramString}`;
    return request({
      method: 'GET',
      url: `/api/${resourceName}${formattedParamString}`,
    })
      .then((response) => {
        if (!response) {
          return Promise.reject(Error('Network error. Check your network connections and refresh the page.'));
        }
        return this.setState(
          {
            apiData: response.data,
            isTableLoading: false,
            isCountLoading: true,
          },
          () => this.getCount(formattedParamString),
        );
      })
      .catch(() =>
        this.setState(
          {
            apiData: {},
            isTableLoading: false,
            isCountLoading: true,
          },
          () => this.getCount(formattedParamString),
        ));
  };

  handleFilterChange = (value, type) => {
    if (value) {
      return this.setState(
        ({ filters }) => ({ filters: { ...filters, [type]: value }, offset: 0 }),
        () => this.getData(),
      );
    }
    return this.setState(
      ({ filters: { [type]: ignoredUnusedVar, ...restOfFilters } }) => ({ filters: { ...restOfFilters }, offset: 0 }),
      () => this.getData(),
    );
  };

  resetFilters = () => {
    const { preservedFilter } = this.props;
    this.setState(
      ({ filters: prevFilters }) => ({ filters: preservedFilter ? { [preservedFilter]: prevFilters[preservedFilter] } : {} }),
      () => this.getData(),
    );
  };

  handleSort = (column) => {
    const { sortColumn, sortDirection } = this.state;

    if (column !== sortColumn) {
      this.setState(
        {
          sortColumn: column,
          sortDirection: 'desc',
          offset: 0,
        },
        () => this.getData(),
      );

      return;
    }

    this.setState({ sortDirection: sortDirection === 'desc' ? 'asc' : 'desc', offset: 0 }, () => this.getData());
  };

  getCount = paramString =>
    request({
      method: 'GET',
      url: `/api/count/${this.props.resourceName}${paramString || ''}`,
    })
      .then((response) => {
        if (!response) {
          return Promise.reject(Error('Network error. Check your network connections and refresh the page.'));
        }
        return this.setState({ count: response.data.count, isCountLoading: false });
      })
      .catch(() => this.setState({ count: 0, isCountLoading: false }));

  handlePagination = offset => this.setState({ offset }, () => this.getData());

  updateAlertStatus = (alertIdentifier, status) =>
    this.setState({ isAlertsLoading: true }, () =>
      request({
        method: 'PATCH',
        url: '/api/alerts/',
        data: [{ alertIdentifier, status }],
      })
        .then((response) => {
          this.setState(prevState => ({
            isAlertsLoading: false,
            displayRefresh: true,
            updateAlertStatusError: false,
            patchedAlert: response.data.data[0],
            apiData: {
              ...prevState.apiData,
              results: updateTableWithAlertStatus(prevState.apiData.results, response.data.data),
            },
          }));
        })
        .catch(() => this.setState({ isAlertsLoading: false, updateAlertStatusError: true })));

  // onExpand and expandedRowKey are used for forcing the table to only have one expanded row at a time
  onExpand = (_, record) => {
    if (this.state.expandedRowKey[0] === record.id) {
      return this.setState({ expandedRowKey: [] });
    }
    return this.setState({ expandedRowKey: [record.id] });
  };

  render() {
    const { children, defaultSort, resourceName, name } = this.props;
    const {
      apiData,
      isTableLoading,
      count,
      sortColumn,
      sortDirection,
      filters,
      offset,
      isCountLoading,
      patchedAlert,
      isAlertsLoading,
      updateAlertStatusError,
      expandedRowKey,
      displayRefresh,
    } = this.state;
    return children({
      sourceData: apiData.results,
      isLoading: isTableLoading,
      handleFilterChange: this.handleFilterChange,
      handleSort: this.handleSort,
      defaultSort,
      resourceName,
      name,
      hasMore: apiData.hasMore,
      count: isTableLoading || isCountLoading ? undefined : count,
      isCountLoading,
      queryParams: { sort: `${sortColumn}${sortDirection === 'desc' ? ' desc' : ''}`, ...filters, offset },
      isDefaultTableView: offset === 0 && sortColumn === defaultSort && !Object.keys(filters).length,
      resetFilters: this.resetFilters,
      handlePagination: this.handlePagination,
      patchedAlert,
      isAlertsLoading,
      updateAlertStatusError,
      updateAlertStatus: this.updateAlertStatus,
      expandedRowKey,
      onExpand: this.onExpand,
      displayRefresh,
      handleRefresh: this.getData,
    });
  }
}

export default IntermediateDataProvider;
