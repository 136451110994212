import React from 'react';
import request from '../request';

const useAssets = (systemName) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(false);

  const getData = serviceName => (serviceName
    ? request({
      method: 'GET',
      url: `/api/ix/private/services/assets?serviceName=${encodeURIComponent(serviceName)}`,
    })
      .then((response) => {
        setData([...new Set(response.data.map(asset => asset.asset))]
          .map(asset => response.data.find(obj => obj.asset === asset)));
        setLoading(false);
        setError(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      })
    : setLoading(false)
  );

  React.useEffect(() => {
    setLoading(true);
    getData(systemName);
  }, [systemName]);

  return [{ loading, error, data }];
};

export default useAssets;
