import { useState, useLayoutEffect } from 'react';

const useResizeListener = () => {
  const [documentWidth, setDocumentWidth] = useState(() => document.body.clientWidth);

  useLayoutEffect(() => {
    const setFromEvent = () => setDocumentWidth(document.body.clientWidth);
    window.addEventListener('resize', setFromEvent);

    return () => {
      window.removeEventListener('resize', setFromEvent);
    };
  }, []);

  return documentWidth;
};

export default useResizeListener;
