import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '@chainalysis/react-ui';
import { severityMap } from '../../utils';

export const ErrorMessage = styled.p`
  color: #cb1a1a;
`;

export const InvalidResponseMsg = styled.p`
  text-align: center;
  padding: 5em 4em;
`;

export const ListTerm = styled.span`
  font-weight: 500;
  flex: 0 0 12rem;
`;

export const ListDetails = styled.span`
  flex: 1 1 auto;
  max-width: ${props => (props.alerts ? 'none' : '700px')};
  user-select: ${props => (props.selectAll ? 'all' : 'auto')};
  -moz-user-select: ${props => (props.selectAll ? 'all' : 'auto')};
  -ms-user-select: ${props => (props.selectAll ? 'all' : 'auto')};
  user-select: ${props => (props.selectAll ? 'all' : 'auto')};
`;

export const AlertTag = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  font-weight: 500;
  width: 80px;
  letter-spacing: 0.5px;
  font-size: 0.9em;
  text-align: center;
  display: inline-block;
  background-color: ${props => severityMap[props.level].background};
  color: ${props => severityMap[props.level].color};
`;

export const BetaTag = styled.div`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
`;

// eslint-disable-next-line react/prop-types
const BasicSectionHeader = ({ type, className, text, ...props }) => (
  <div className={className} {...props}><Icon type={type} /> {text}</div>
);

export const SectionHeader = styled(BasicSectionHeader)`
  font-size: 20px;
  font-weight: bold;
  color: #293972;
`;

export const InfoSectionHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  flex: 1;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #595959;
`;

export const RiskName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.color || '#595959'};
`;

export const PanelHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.42px;
  color: #6774A4;
  display: inline-block;
  text-transform: uppercase;
`;

export const PanelLink = styled(PanelHeader)`
  cursor: pointer;
  color: #798592;
`;

export const CategoryHeader = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  text-transform: uppercase;
  letter-spacing: normal;
  color: #64707d;
`;

export const TableHeader = styled.span`
  color: #293972;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  flex: 1;
`;

export const DottedTerm = styled.span`
  text-decoration-style: dotted;
  text-decoration-line: underline;
`;

/**
 * InfoIcon to be used for Tooltips.
 *
 * @param {className} is required for styling
 * @param {onMouseEnter} is required for Tooltip
 * @param {onMouseLeave} is required for Tooltip
 */
// eslint-disable-next-line react/prop-types
const BaseInfoIcon = ({ className, onMouseEnter, onMouseLeave }) => (
  <Icon
    className={className}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    type="info-circle"
    theme="filled"
  />
);

export const InfoIcon = styled(BaseInfoIcon)`
  font-size: 14px;
  color: ${props => (props.active ? '#293972' : '#bfbfbf')};
  ${props => (props.align && 'vertical-align: baseline')};
`;

export const ServiceName = styled.div`
  display: inline-block;
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
  color: #282828;
`;

export const OrgName = styled.div`
  display: inline-block;
  color: #595959;
  line-height: ${props => (props.small ? '24px' : '38px')};
  font-size: ${props => (props.small ? '16px' : '30px')};
  font-weight: ${props => (props.small ? 'normal' : 500)};
  margin-right: 0.5rem;
`;

export const CategoryName = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #595959;
`;
