import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from '@chainalysis/react-ui';
import request from 'utils/request';

const WatchButton = ({ asset, address, serviceName, orgName, isInCollapsed, isWatched, onToggleWatch }) => {
  const title = isWatched ? 'Remove this service from your Watchlist' : 'Add this service to your Watchlist';
  const buttonCss = css`
    position: ${isInCollapsed ? 'relative' : 'absolute'};
    ${!isInCollapsed && 'top: 0.75rem; right: 1.25rem;'}
    font-size: 24px;
    color: ${isWatched ? '#293972' : '#bfbfbf'};
    :hover {
      color: #293972;
    }
  `;

  async function toggleWatch() {
    const watch = !isWatched;
    const response = await request({
      method: 'POST',
      url: `/api/ix/private/watchlist/${asset}/${address}/watch`,
      data: {
        serviceName,
        orgName,
        watch,
      },
    });
    if (response.data.success) { onToggleWatch(serviceName, orgName, watch, true); }
  }

  return serviceName || orgName ? (
    <Tooltip title={title}>
      <Icon type="eye" theme={isWatched ? 'filled' : 'outlined'} css={buttonCss} onClick={toggleWatch} />
    </Tooltip>
  ) : null;
};

WatchButton.propTypes = {
  asset: PropTypes.string,
  address: PropTypes.string,
  serviceName: PropTypes.string,
  orgName: PropTypes.string,
  isWatched: PropTypes.bool,
  isInCollapsed: PropTypes.bool,
  onToggleWatch: PropTypes.func.isRequired,
};

WatchButton.defaultProps = {
  asset: null,
  address: null,
  isWatched: false,
  isInCollapsed: false,
  serviceName: null,
  orgName: null,
};

export default WatchButton;
