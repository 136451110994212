import React from 'react';
import Loadable from 'react-loadable';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Loader, ErrorBoundary } from '@chainalysis/react-ui';
import { PrivateRoute } from '.';
import { GlobalContextProvider, GlobalContextConsumer } from './providers/GlobalContextProvider';
import { UiStateProvider } from './providers/UiStateProvider';

const Loading = () => <Loader container />;

const LoadableLogin = Loadable({
  loader: () => import('./login/Login'),
  loading: Loading,
});

const LoadableForgotPassword = Loadable({
  loader: () => import('./ForgotPassword'),
  loading: Loading,
});

const LoadableResetPassword = Loadable({
  loader: () => import('./ResetPassword'),
  loading: Loading,
});

const LoadablePermissionDenied = Loadable({
  loader: () => import('./Unauthorized'),
  loading: Loading,
})

const LoadablePactsafeAgreement = Loadable({
  loader: () => import('./pactsafeAgreement/PactsafeAgreement'),
  loading: Loading,
})

const LoadableNav = Loadable({
  loader: () => import('./Nav'),
  loading: Loading,
});

const LoadableHome = Loadable({
  loader: () => import('./containers/ServicesContainer'),
  loading: Loading,
});

const LoadableAlerts = Loadable({
  loader: () => import('./containers/AlertsContainer'),
  loading: Loading,
});

const LoadableExplanation = Loadable({
  loader: () => import('./containers/ExplanationContainer'),
  loading: Loading,
});

const LoadableExchange = Loadable({
  loader: () => import('./containers/ProfileContainer'),
  loading: Loading,
});

const LoadableAlertsRules = Loadable({
  loader: () => import('./containers/AlertsRulesContainer'),
  loading: Loading,
});

const App = () => (
  <ErrorBoundary>
    <GlobalContextProvider>
      <UiStateProvider>
        <GlobalContextConsumer>
          {context => (
            <Router>
              <React.Fragment>
                <Switch>
                  <Route exact path="/login" render={null} />
                  <Route exact path="/forgot-password" render={null} />
                  <Route exact path="/password-reset" render={null} />
                  <PrivateRoute
                    isFirstRender={context.isFirstRender}
                    isAuth={context.isAuthenticated}
                    isAgreementSigned={context.isAgreementSigned}
                    hasKryptosAccess={context.hasKryptosAccess}
                    component={LoadableNav}
                    fullName={context.fullName}
                  />
                </Switch>
                <Switch>
                  <Route
                    path="/login"
                    render={routeProps => <LoadableLogin checkAuth={context.checkAuth} {...routeProps} />}
                  />
                  <Route
                    path="/forgot-password"
                    render={routeProps => <LoadableForgotPassword />}
                  />
                  <Route
                    path="/password-reset"
                    render={routeProps => <LoadableResetPassword {...routeProps} />}
                  />
                  <Route
                    path="/access-denied"
                    render={routeProps => <LoadablePermissionDenied
                      isAuth={context.isAuthenticated}
                      hasKryptosAccess={context.hasKryptosAccess}
                    />}
                  />
                  <Route
                    path="/agreement"
                    render={() => (
                      <LoadablePactsafeAgreement
                        isAuth={context.isAuthenticated}
                        isAgreementSigned={context.isAgreementSigned}
                        setIsAgreementSigned={context.setIsAgreementSigned}
                      />
                    )}
                  />
                  {context.isBasicAccount || (
                    <PrivateRoute
                      isFirstRender={context.isFirstRender}
                      isAuth={context.isAuthenticated}
                      isAgreementSigned={context.isAgreementSigned}
                      hasKryptosAccess={context.hasKryptosAccess}
                      exact
                      path="/risk-score-methodology"
                      component={LoadableExplanation}
                    />
                  )}
                  <PrivateRoute
                    isFirstRender={context.isFirstRender}
                    isAuth={context.isAuthenticated}
                    isAgreementSigned={context.isAgreementSigned}
                    hasKryptosAccess={context.hasKryptosAccess}
                    exact
                    path="/services"
                    component={LoadableHome}
                  />
                  {context.isBasicAccount || (
                  <PrivateRoute
                    isFirstRender={context.isFirstRender}
                    isAuth={context.isAuthenticated}
                    isAgreementSigned={context.isAgreementSigned}
                    hasKryptosAccess={context.hasKryptosAccess}
                    exact
                    path="/alerts"
                    component={LoadableAlerts}
                  />
                  )}
                  {context.isBasicAccount || (
                  <PrivateRoute
                    isFirstRender={context.isFirstRender}
                    isAuth={context.isAuthenticated}
                    isAgreementSigned={context.isAgreementSigned}
                    hasKryptosAccess={context.hasKryptosAccess}
                    exact
                    path="/alerts/rules"
                    component={LoadableAlertsRules}
                  />
                  )}
                  <PrivateRoute
                    isFirstRender={context.isFirstRender}
                    isAuth={context.isAuthenticated}
                    isAgreementSigned={context.isAgreementSigned}
                    hasKryptosAccess={context.hasKryptosAccess}
                    exact
                    path="/services/:asset/:address"
                    component={LoadableExchange}
                  />
                  {context.isAuthenticated && <Route render={() => <Redirect to="/services" />} />}
                </Switch>
              </React.Fragment>
            </Router>
          )}
        </GlobalContextConsumer>
      </UiStateProvider>
    </GlobalContextProvider>
  </ErrorBoundary>
);
export default App;
