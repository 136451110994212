export const eventTypes = {
  NOTE: 'NOTE',
  OPEN_AND_NOTE: 'OPEN_AND_NOTE',
  CLOSE_AND_NOTE: 'CLOSE_AND_NOTE',
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
};

export const breakpoints = {
  xs: 480,
  small: 576,
  medium: 768,
  large: 992,
  tablet: 1100,
  xl: 1315,
  xxl: 1600,
};

export const xxSmallPageLimit = 5;
export const xSmallPageLimit = 10;
export const smallPageLimit = 25;
export const mediumPageLimit = 50;
export const largePageLimit = 100;

export const primaryColor = '#293972';
export const dangerColor = '#f5222d';
