import { ssoRequest } from './request'


const hideZendeskWebWidget = () => {
  try {
    // eslint-disable-next-line dot-notation
    window.zE('webWidget', 'hide');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const bootZendesk = (chatSupportEnabled, phoneSupportEnabled) => {
  if (chatSupportEnabled || phoneSupportEnabled) {
    ssoRequest({
      method: 'GET',
      url: '/api/sso/auth/userinfo',
    }).then(response => {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactOptions: {
            enabled: true,
            chatLabelOnline: { '*': 'Chat' },
            contactFormLabel: { '*': 'Email' },
          },
          launcher: {
            label:     {'*': 'Help'},
            talkLabel: {'*': 'Help'},
            chatLabel: {'*': 'Help'}
          },
          contactForm: {
            suppress: false,
            title: {'*': 'Email'}
          },
          chat: {
            suppress: !chatSupportEnabled,
            title: {'*': 'Chat'}
          },
          talk: {
            suppress: !phoneSupportEnabled,
            title: {'*': 'Callback'}
          },
        }
      })
      window.zE('webWidget', 'identify', {
        name: response.data.fullName,
        email: response.data.userName,
        organization: String(response.data?.organizations[0].orgName),
      })
      window.zE('webWidget', 'show')
    })
  } else {
    hideZendeskWebWidget()
  }
}

export default bootZendesk

export { hideZendeskWebWidget };
