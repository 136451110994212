/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import {
  Badge,
  Typography,
  Icon,
  Popover,
  Button,
} from "@chainalysis/react-ui";
import { jsx } from "@emotion/core";
import { capitalize } from "lodash";
import {
  formatDate,
  formatNumber,
  getCategoryText,
  alertOptions,
  exposureOptions,
  alertCategoryOptions,
  alertTypeOptions,
} from ".";

const { Text } = Typography;

// Helper functions
export const insertColumnIf = (condition, column) =>
  condition ? [column] : [];

export const renderTableRow = (headerMap, dataMap, header, text, record) => {
  if (headerMap[header].renderedWithData) {
    return dataMap[header](record);
  }
  return typeof dataMap[header] === "function"
    ? dataMap[header](text)
    : dataMap[header]
    ? dataMap[header][text]
    : text;
};

export const generateRowKey = (record, rowKey) =>
  Array.isArray(rowKey)
    ? rowKey.reduce((prev, curr) => prev + record[curr], "")
    : record[rowKey];

export const generateRuleDescription = (
  min,
  max,
  direction,
  type,
  category
) => {
  const rangeString =
    max === null
      ? `>${formatNumber(min, "currencyNoDecimal")}`
      : `${formatNumber(min, "currencyNoDecimal")}-${formatNumber(
          max,
          "currencyNoDecimal"
        )}`;
  const toOrFrom = direction === "SENT" ? "to" : "from";
  const directlyOrIndirectlyToOrFrom = type
    ? ` ${type.toLowerCase()}ly ${toOrFrom} ${category}`
    : "";
  return `${rangeString} ${direction.toLowerCase()}${
    category ? directlyOrIndirectlyToOrFrom : ""
  }`;
};

// Alerts Table
export const alertsHeaderMap = {
  level: {
    title: "Severity",
    width: 100,
    filterName: "level",
    isFilterable: true,
    filters: alertOptions,
    filterMultiple: false,
    isSortable: true,
  },
  createdAt: { title: "Created At", isSortable: true, width: 175 },
  alertStatus: {
    title: "Alert Status",
    width: 125,
  },
  userid: { title: "User ID", width: 100 },
  category: {
    title: "Alert Category",
    filterName: "category",
    width: 175,
    isFilterable: true,
    filters: alertCategoryOptions,
    filterMultiple: false,
  },
  service: { title: "Name", width: 150 },
  exposureType: {
    title: "Exposure",
    width: 50,
    filterName: "exposureType",
    isFilterable: true,
    filters: exposureOptions,
    filterMultiple: false,
  },
  alertAmountUsd: {
    title: "Alert Amount",
    textAlign: "right",
    width: 100,
    isSortable: true,
  },
};

export const servicesHeaderMap = {
  rank: { title: "Rank", width: 65 },
  name: { title: "Name" },
  category: { title: "Category" },
  score: {
    title: "Risk Score",
    isFilterable: true,
    filterName: "score",
    width: 150,
    filters: [
      { key: "High", value: "High", text: "High" },
      { key: "Medium", value: "Medium", text: "Medium" },
      { key: "Low", value: "Low", text: "Low" },
    ],
    filterMultiple: false,
  },
  country: { title: "Country of Headquarters" },
  fiatDeposits: { title: "Fiat" },
};

export const servicesDataMap = {
  name: (service) => service || "—",
  score: {
    High: <Badge text="High" status="error" />,
    Medium: <Badge text="Medium" status="warning" />,
    Low: <Badge text="Low" status="success" />,
  },
  category: (category) => getCategoryText(category) || "—",
  fiatDeposits: (fiat) => (fiat ? fiat.join(", ") : "—"),
};

export const topCounterpartiesHeaderMap = {
  name: { title: "Name", renderedWithData: true },
  category: { title: "Category" },
  percentage: { title: "% of Total Volume", isSortable: true },
  sum: { title: "Total Volume", isSortable: true },
  sent: { title: "Sent", isSortable: true },
  received: { title: "Received", isSortable: true },
};

export function topCounterpartiesDataMap(history) {
  return {
    name: (service) =>
      service.name ? (
        <Button
          type="link"
          onClick={() =>
            history.push(
              `/services/${service.rootAsset}/${service.rootAddress}`
            )
          }
        >
          {service.name}
        </Button>
      ) : (
        "-"
      ),
    category: (category) => getCategoryText(category) || "—",
    percentage: (percent) => `${formatNumber(percent, "percent")}%`,
    sum: (sum) => formatNumber(sum, "currency"),
    sent: (sent) => formatNumber(sent, "currency"),
    received: (received) => formatNumber(received, "currency"),
  };
}

export const serviceAlertsHeaderMap = (isBasicAccount = false) => ({
  chainalysisname: { title: "Service" },
  category: {
    title: "Category",
    filterName: "category",
    isSortable: true,
    isFilterable: true,
    filters: alertCategoryOptions,
    filterMultiple: false,
  },
  alerttype: {
    title: "Type",
    filterName: "alerttype",
    isSortable: true,
    isFilterable: true,
    filters: alertTypeOptions,
    filterMultiple: false,
  },
  weekstartdate: { title: "Week Start", isSortable: true },
  weekenddate: { title: "Week End", isSortable: true },
  actualvalue: { title: "Amount", renderedWithData: true },
  alertrule: { title: isBasicAccount ? "Highlight" : "Alert Triggered By" },
});

export const serviceAlertsDataMap = {
  category: (category) => getCategoryText(category) || "—",
  alerttype: (type) =>
    type.length > 24 ? (
      <Popover placement="top" content={type}>
        {type}
      </Popover>
    ) : (
      type
    ),
  weekstartdate: (date) => formatDate(date, false),
  weekenddate: (date) => formatDate(date, false),
  // eslint-disable-next-line max-len
  actualvalue: (data) =>
    `${formatNumber(
      data.actualvalue,
      data.alertunit === "%" ? "percent" : "currency"
    )}${data.alertunit === "%" ? "%" : ""}`,
  alertrule: (rule) =>
    rule.length > 24 ? (
      <Popover placement="top" content={rule}>
        {rule}
      </Popover>
    ) : (
      rule
    ),
};

export const transfersHeaderMap = {
  hash: { title: "" },
  datetime: { title: "Transfer Date", width: 150, fixed: "left" },
  direction: { title: "Direction", width: 120 },
  valueUSD: { title: "Amount (USD)", width: 120 },
  value: { title: "Amount", renderedWithData: true, width: 150 },
  receivingAddress: { title: "Receiving Address" },
};

export const transfersDataMap = {
  hash: (hash) => (
    <Text copyable>
      <Icon type="swap" /> {hash}
    </Text>
  ),
  datetime: (date) => formatDate(date * 1000),
  direction: (direction) => capitalize(direction),
  valueUSD: (amount) => formatNumber(amount, "currency"),
  value: (data) => `${formatNumber(data.value / 1e8, "asset")} ${data.asset}`,
  receivingAddress: (address) => address,
};
