import React from 'react';
import request from '../request';

const useProfileData = (asset, address) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setProfileData] = React.useState({});
  const [error, setError] = React.useState(false);

  const getData = (asset, address) =>
    request({
      method: 'GET',
      url: `/api/ix/private/clusters/${asset}/${address}`,
    })
      .then((response) => {
        setProfileData(response.data);
        setLoading(false);
        setError(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });

  React.useEffect(() => {
    setLoading(true);
    getData(asset, address);
  }, [asset, address]);

  return [{ loading, error, data }];
};

export default useProfileData;
