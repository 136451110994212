import React from 'react';
import { useBasicAccount } from 'utils/hooks';
import request from '../request';

const rootUrl = '/api/ix/private/exchange';

const toUpper = string => string.toUpperCase();

const useSupportedAssets = (rootAsset, rootAddress) => {
  const isBasicAccount = useBasicAccount();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState(false);

  const getData = (asset, address) => (!isBasicAccount
    ? request({
      method: 'GET',
      url: `${rootUrl}/supported-privacy-stable-coins/${asset}/${address}`,
    })
      .then((response) => {
        const { stableCoins, privacyCoins, tradingPairs } = response.data;
        setData({
          stableCoins: stableCoins.map(toUpper),
          privacyCoins: privacyCoins.map(toUpper),
          tradingPairs: tradingPairs.map(toUpper),
        });
        setLoading(false);
        setError(false);
      }).catch(() => {
        setError(true);
        setLoading(false);
      })
    : setLoading(false)
  );

  React.useEffect(() => {
    setLoading(true);
    getData(rootAsset, rootAddress);
  }, [rootAsset, rootAddress]);

  return [{ loading, error, data }];
};

export default useSupportedAssets;
