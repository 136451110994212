import styled from '@emotion/styled';
import heroBackground from '../../img/hero-bg.svg';

export const PieChart = styled.div`
  .highcharts-root path {
    stroke: #a2a2a1;
  }

  .highcharts-root .highcharts-drilldown-data-label text {
    color: #353535 !important;
    fill: #353535 !important;
  }
`;

export const MultiSelectItem = styled.li`
  height: 36px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 36px;
  text-overflow: ellipsis;

  &:hover {
    background-color: #e6f7ff;
  }
`;

export const MultiSelectHeader = styled.div`
  margin: 0.8rem 0 0.6rem;
  padding: 0 1.14285714rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.78571429em;
  font-weight: 500;
  text-transform: uppercase;
`;

export const FullWidthContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

export const DashboardHero = styled.div`
  text-align: center;
  height: 16rem;
  background-color: #293972;
  background-size: cover;
  background-image: url("${heroBackground}");
  padding-top: 7rem
`;
