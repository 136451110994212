/** @jsx jsx */
/** @jsxFrag React.Fragment */
/* eslint-disable react/no-this-in-sfc */
import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import { withRouter } from "react-router-dom";
import hcnd from "highcharts/modules/no-data-to-display";
import { jsx } from "@emotion/core";
import { formatNumber, generateTimeSeriesRange } from "../utils";
import { CustomSet } from "../utils/customPropTypes";

if (!Highcharts.Chart.prototype.hasData) {
  hcnd(Highcharts);
}

const chartElementId = "benchmark-series-container";

const BenchmarkSeriesChart = ({
  data,
  selected,
  allSelected,
  noDataMessage,
  serviceName,
  wideChart,
}) => {
  const getNoDataMessage = () => {
    if (allSelected) {
      return `Select fewer categories to compare ${serviceName} with indices`;
    }
    if (selected.size === 0) {
      return "No transfer data is available for your selected filters";
    }

    return noDataMessage;
  };

  const setChart = (series, pointStart, pointEnd, noData) =>
    Highcharts.chart(
      chartElementId,
      {
        chart: {
          type: "line",
          showAxes: true,
          defaultSeriesType: "line",
        },
        lang: { thousandsSep: ",", noData },
        noData: {
          useHTML: true,
          style: {
            fontWeight: "normal",
            fontSize: "16px",
            fontFamily: `BlinkMacSystemFont, -apple-system, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans','Droid Sans',
          'Helvetica Neue', Helvetica, Arial, sans-serif`,
          },
        },
        responsive: {
          rules: [
            {
              condition: { maxWidth: 1400 },
              chartOptions: {
                lang: { noData: "" },
                noData: {},
              },
            },
          ],
        },
        credits: { enabled: false },
        title: undefined,
        xAxis: {
          type: "datetime",
          tickInterval: 24 * 3600 * 1000 * 21,
          dateTimeLabelFormats: {
            month: "%e",
            year: "'%b",
          },
        },
        yAxis: {
          min: 0,
          title: { text: "Weighted Weekly Average" },
          labels: {
            formatter() {
              return `${this.value}%`;
            },
          },
        },

        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom",
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 4,
            },
          },
          series: {
            pointStart: pointStart * 1000,
            pointInterval: 24 * 3600 * 1000 * 7,
          },
        },
        series,
        tooltip: {
          split: true,
          formatter() {
            const {
              point: { index },
              series: { data },
            } = this.points[0];
            const points = this.points.map(
              (benchmarkIndex) =>
                `<span>${benchmarkIndex.series.name}: </span>
              <span>
                <strong>${
                  benchmarkIndex.y === -1
                    ? "No data available"
                    : `${formatNumber(benchmarkIndex.y, "percent")}%`
                }</strong>
              </span>`
            );
            return [
              `<span style="font-size: 10px">${generateTimeSeriesRange(
                this.x,
                index,
                pointEnd,
                "WEEK",
                data.length
              )}</span>`,
              ...(points.join("").length === 0
                ? ["No transfers in the selected categories."]
                : points),
            ];
          },
        },
      },
      (chart) => {
        // Show markers if there's just one point
        if (series && series.length > 0 && series[0].data.length === 1) {
          chart.update(
            { plotOptions: { line: { marker: { enabled: true } } } },
            true
          );
        }
      }
    );

  React.useEffect(() => {
    const noData = getNoDataMessage();
    setChart(data.series, data.pointStart, data.pointEnd, noData);
  }, [wideChart]);

  return <div id={chartElementId} css={{ height: "35vh" }} />;
};

BenchmarkSeriesChart.propTypes = {
  data: PropTypes.shape({
    pointStart: PropTypes.number,
    pointEnd: PropTypes.number,
    series: PropTypes.array,
  }),
  selected: CustomSet,
  allSelected: PropTypes.bool.isRequired,
  noDataMessage: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  wideChart: PropTypes.bool.isRequired,
};

BenchmarkSeriesChart.defaultProps = { data: undefined, selected: undefined };

export default withRouter(BenchmarkSeriesChart);
