// This component provides tabular data. Use this provider when data is definite.
// Only an initial API request is required. All actions happen on the frontend.
// State is not saved in the URL, and the frontend does not provide additional caching.

import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import queryString from 'query-string';
import request from '../../utils/request';

const applyDataTransforms = (data, sort, pageLimit, offset) =>
  orderBy(data, [sort.column], [sort.direction]).slice(offset, offset + pageLimit);

class SimpleDataProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    defaultSort: PropTypes.string.isRequired,
    resourceName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pageLimit: PropTypes.number.isRequired,
    defaultSortDirection: PropTypes.string,
    radioSelected: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    defaultSortDirection: 'desc',
    radioSelected: false,
    isLoading: false,
  }

  state = {
    apiData: {},
    isTableLoading: true,
    sortDirection: this.props.defaultSortDirection,
    sortColumn: this.props.defaultSort,
    offset: 0,
    activeKey: '',
    radioSelected: this.props.radioSelected,
  };

  constructor(props) {
    super(props);
    this.requestQueue = [];
  }

  componentDidMount() {
    const { isLoading } = this.props;
    if (!isLoading) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    const { resourceName, isLoading } = this.props;

    if (resourceName !== prevProps.resourceName && !isLoading) {
      this.getData();
    }
  }

  componentWillUnmount() {
    delete this.requestQueue;
  }

  // TODO: Responses will be unified by the backend
  getParsedResponse = (response, name, paramString) => {
    if (name === 'counterparties') {
      return {
        totalCount: response.data.counterparties.length,
        [paramString]: response.data.counterparties,
      };
    }
    if (name === 'transfers') {
      return {
        totalCount: response.data.totalCount,
        [paramString]: response.data.items,
      };
    }
    return {
      totalCount: response.data.length,
      [paramString]: response.data,
    };
  }

  getData = (paramString = '') => {
    const { resourceName, name } = this.props;
    const { apiData } = this.state;
    this.requestQueue.push(resourceName);
    this.setState({ isTableLoading: true });
    return request({
      method: 'GET',
      url: `/api/ix/private/${resourceName}${paramString}`,
    })
      .then((response) => {
        if (!response) {
          return Promise.reject(Error('Network error. Check your network connections and refresh the page.'));
        }
        if (resourceName === this.requestQueue.pop()) {
          return this.setState({
            apiData: {
              ...apiData,
              ...this.getParsedResponse(response, name, paramString),
            },
            activeKey: paramString,
            isTableLoading: false,
          });
        }
        return this.setState({
          apiData: { ...apiData },
          activeKey: paramString,
          isTableLoading: false,
        });
      })
      .catch(() =>
        this.setState({ isTableLoading: false }));
  };

  handleSort = (column) => {
    const { sortColumn, sortDirection } = this.state;
    if (column !== sortColumn) {
      this.setState({
        sortColumn: column,
        sortDirection: 'desc',
        offset: 0,
      });

      return;
    }

    this.setState({ sortDirection: sortDirection === 'desc' ? 'asc' : 'desc', offset: 0 });
  };

  handlePagination = offset => this.setState({ offset });

    resetFilters = () => {
      this.setState({ activeKey: '', offset: 0 });
    };

  handleFilterChange = (value, key) => {
    this.setState({ isTableLoading: true });
    const { activeKey, apiData } = this.state;
    const parsedQueryString = queryString.parse(activeKey);
    if (value === null) {
      const { [key]: ignoredUnusedVar, ...restOfQuery } = parsedQueryString;
      return this.setState({
        activeKey: queryString.stringify({ ...restOfQuery }),
        isTableLoading: false,
      });
    }
    const newActiveKey = queryString.stringify({ ...parsedQueryString, [key]: value });
    if (apiData[newActiveKey]) {
      return this.setState({
        activeKey: newActiveKey,
        isTableLoading: false,
      });
    }
    return this.setState({
      apiData: { ...apiData, [newActiveKey]: apiData[''].filter(item => item[key] === value) },
      activeKey: newActiveKey,
      isTableLoading: false,
    });
  }

  handleRadioSelect = (selected, paramString) =>
    this.setState({ radioSelected: selected, offset: 0 }, () => this.getData(paramString));

  render() {
    const { children, resourceName, name, defaultSort, pageLimit } = this.props;
    const { apiData, isTableLoading, sortColumn, sortDirection, offset, radioSelected, activeKey } = this.state;
    const { totalCount } = apiData;
    const sourceData = applyDataTransforms(
      apiData[activeKey],
      { direction: sortDirection, column: sortColumn },
      pageLimit,
      offset,
    );
    const objOfFilters = queryString.parse(activeKey);
    return children({
      sourceData,
      isLoading: isTableLoading,
      count: isTableLoading ? undefined : totalCount,
      defaultSort,
      resourceName,
      handleSort: this.handleSort,
      name,
      queryParams: { sort: `${sortColumn}${sortDirection === 'desc' ? ' desc' : ''}`, offset, ...objOfFilters },
      isDefaultTableView: offset === 0 && sortColumn === defaultSort && !Object.keys(objOfFilters).length,
      handlePagination: this.handlePagination,
      hasMore: (apiData[activeKey] ? apiData[activeKey].length : 0) > offset + pageLimit,
      handleRadioSelect: this.handleRadioSelect,
      radioSelected,
      handleFilterChange: this.handleFilterChange,
      resetFilters: this.resetFilters,
    });
  }
}

export default SimpleDataProvider;
