/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import { Drawer, Icon, Layout, Button, Affix } from "@chainalysis/react-ui";
import { jsx, css } from "@emotion/core";
import { DrawerHandle } from "./styled/ui";
import { useBasicAccount } from "utils/hooks";

const { Sider } = Layout;

const triggerCss = css`
  position: absolute;
  top: 1rem;
  right: -1rem;
  opacity: 0;
  z-index: 1000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: opacity 150ms ease;
`;

const CollapseTrigger = ({ collapsed, visible, onClick }) => {
  const [type, setType] = React.useState("default");
  return (
    <Button
      css={triggerCss}
      shape="circle"
      type={type}
      style={{ opacity: visible ? 1 : 0 }}
      icon={collapsed ? "right" : "left"}
      onMouseLeave={() => setType("default")}
      onMouseEnter={() => setType("primary")}
      onClick={onClick}
    />
  );
};
CollapseTrigger.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Sidebar = ({
  children,
  collapsed,
  onCollapse,
  isDesktop,
  containerPadding,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [triggerVisible, setTriggerVisible] = React.useState(false);
  const [stateCollapsed, setStateCollapsed] = React.useState(collapsed);
  const [hideContent, setHideContent] = React.useState(false);

  // Make sure internal state always respects passed prop
  React.useLayoutEffect(() => {
    setStateCollapsed(collapsed);
  }, [collapsed]);

  // We need to have final layout with correct dimensions before we fire onCollapse
  const onFinishedCollapse = (e) => {
    e.persist();
    if (e.propertyName === "width") {
      setHideContent(false);
      onCollapse(stateCollapsed);
    }
  };

  const isBasicAccount = useBasicAccount();
  const desktopSiderInnerDivStyle = isBasicAccount
    ? { height: "calc(100vh - 64px)" }
    : {};

  return isDesktop ? (
    <Sider
      trigger={null}
      collapsedWidth={60}
      theme="light"
      width={354}
      css={{ backgroundColor: "#fff", minWidth: 250 }}
      collapsible
      collapsed={stateCollapsed}
      onTransitionEnd={onFinishedCollapse}
      onMouseEnter={() => setTriggerVisible(true)}
      onMouseLeave={() => setTriggerVisible(false)}
    >
      <Affix>
        <CollapseTrigger
          collapsed={stateCollapsed}
          visible={triggerVisible}
          onClick={() => {
            setStateCollapsed(!stateCollapsed);
            setHideContent(true);
          }}
        />
      </Affix>
      <div
        style={desktopSiderInnerDivStyle}
        css={{
          overflow: "auto",
          top: "0",
          bottom: "0",
          position: "sticky",
          height: "100vh",
          overflowY: "auto",
          transition: "opacity 0.25s ease",
          opacity: hideContent ? 0 : 100,
          padding: containerPadding,
        }}
      >
        {children}
      </div>
    </Sider>
  ) : (
    <>
      <DrawerHandle onClick={() => setVisible(true)}>
        <Icon type="menu" />
      </DrawerHandle>
      <Drawer
        width="350"
        destroyOnClose
        bodyStyle={{ padding: 0 }}
        placement="left"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <div
          css={{
            overflow: "auto",
            top: "0",
            bottom: "0",
            position: "sticky",
            height: "100vh",
            overflowY: "auto",
            padding: containerPadding,
          }}
        >
          {children}
        </div>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onCollapse: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
  containerPadding: PropTypes.number,
};

Sidebar.defaultProps = {
  collapsed: false,
  onCollapse: undefined,
  containerPadding: "1.5rem",
};

export default Sidebar;
