/* eslint-disable max-len */
import React from "react";
import moment from "moment";
import queryString from "query-string";
import filter from "lodash/filter";
import { breakpoints } from "./constants";

export const mq = (size) => `@media (max-width: ${breakpoints[size]}px)`;

export const scoreColorMap = {
  red: "red",
  amber: "yellow",
  green: "green",
};

export const colorStatusMap = {
  red: "error",
  amber: "warning",
  green: "success",
};

export const statusValueToText = {
  OPEN: "Open",
  CLOSED: "Closed",
};

export const severityMap = {
  SEVERE: {
    color: "#a20907",
    background: "#fff0f0",
  },
  HIGH: {
    color: "#c24e00",
    background: "#ffece0",
  },
  MEDIUM: {
    color: "#996b0f",
    background: "#fff9db",
  },
  LOW: {
    color: "#647382",
    background: "#f7f7f7",
  },
};

export const riskScoreOptions = [
  {
    text: "High",
    value: "red",
    key: "red",
  },
  {
    text: "Medium",
    value: "amber",
    key: "amber",
  },
  {
    text: "Low",
    value: "green",
    key: "green",
  },
];

export const alertOptions = [
  {
    text: "Severe",
    value: "SEVERE",
    key: "SEVERE",
  },
  {
    text: "High",
    value: "HIGH",
    key: "HIGH",
  },
  {
    text: "Medium",
    value: "MEDIUM",
    key: "MEDIUM",
  },
  {
    text: "Low",
    value: "LOW",
    key: "LOW",
  },
];

export const getAlertText = (level) =>
  alertOptions.find((severity) => severity.key === level).text;

export const exposureOptions = [
  { key: "DIRECT", value: "DIRECT", text: "Direct" },
  { key: "INDIRECT", value: "INDIRECT", text: "Indirect" },
];
export const directionOptions = [
  { key: "RECEIVED", value: "RECEIVED", text: "Received" },
  { key: "SENT", value: "SENT", text: "Sent" },
];

export const directionMap = {
  RECEIVED: "Received",
  SENT: "Sent",
};

export const assetOptions = [
  { key: "BTC", value: "BTC", text: "Bitcoin (BTC)" },
  { key: "ETH", value: "ETH", text: "Ethereum (ETH)" },
  { key: "BCH", value: "BCH", text: "Bitcoin Cash (BCH)" },
  { key: "LTC", value: "LTC", text: "Litecoin (LTC)" },
  { key: "BAT", value: "BAT", text: "Basic Attention Token (BAT)" },
  { key: "BNB", value: "BNB", text: "Binance Coin (BNB)" },
  // { key: 'BTG', value: 'BTG', text: 'Bitcoin Gold (BTG)' },
  { key: "DAI", value: "DAI", text: "DAI" },
  // { key: 'DASH', value: 'DASH', text: 'DASH' },
  // { key: 'DOGE', value: 'DOGE', text: 'Dogecoin (DOGE)' },
  // { key: 'ETC', value: 'ETC', text: 'Ethereum Classic (ETC)' },
  { key: "GUSD", value: "GUSD", text: "Gemini Dollar (GUSD)" },
  // { key: 'LCC', value: 'LCC', text: 'Litecoin Cash (LCC)' },
  // { key: 'MAID', value: 'MAID', text: 'MaidSafeCoin (MAID)' },
  // { key: 'MKR', value: 'MKR', text: 'Maker (MKR)' },
  { key: "OMG", value: "OMG", text: "OmiseGO (OMG)" },
  { key: "PAX", value: "PAX", text: "Paxos Standard Token (PAX)" },
  { key: "TUSD", value: "TUSD", text: "TrueUSD (TUSD)" },
  { key: "USDC", value: "USDC", text: "USD Coin (USDC)" },
  { key: "USDT", value: "USDT", text: "Tether (USDT)" },
  { key: "USDT_ETH", value: "USDT_ETH", text: "USD Tether Ether (USDT_ETH)" },
  // { key: 'ZEC', value: 'ZEC', text: 'Zcash (ZEC)' },
  { key: "ZRX", value: "ZRX", text: "0x (ZRX)" },
];

export const isAccountBased = (asset) =>
  [
    "BAT",
    "BNB",
    "DAI",
    "ETH",
    "GUSD",
    "OMG",
    "PAX",
    "USDT",
    "TUSD",
    "USDC",
    "USDT_ETH",
    "ZRX",
  ].includes(asset);

export const getAssets = (assets) =>
  filter(assetOptions, (asset) => assets.indexOf(asset.key) > -1);

const categories = {
  atm: {
    color: "#FADF86",
    text: "ATM",
  },
  calculating: { color: "#b1bec5", text: "Calculating", desc: null },
  "child abuse material": {
    color: "#ff1c46",
    text: "Child Abuse Material",
  },
  "coin generation": { color: "#185e20", text: "Coin Generation", desc: null },
  "custom address": { color: "#009688", text: "Custom Address", desc: null },
  "darknet market": {
    color: "#e43b38",
    text: "Darknet Market",
  },
  dust: { color: "#8fa4ad", text: "Dust", desc: null },
  "ethereum contract": {
    color: "#6BF0AF",
    text: "Ethereum Contract",
  },
  "erc20 token": {
    color: "#00E675",
    text: "ERC20 Token",
  },
  "tor market": { color: "#e53935", text: "Tor Market", desc: null },
  ransomware: {
    color: "#8b1b1b",
    text: "Ransomware",
  },
  "terrorist financing": {
    color: "#421313",
    text: "Terrorist Financing",
  },
  scam: {
    color: "#4f3731",
    text: "Scam",
  },
  gambling: {
    color: "#ff5827",
    text: "Gambling",
  },
  "high risk exchange": {
    color: "#880d4f",
    text: "No KYC Exchange",
  },
  "no kyc exchange": {
    color: "#880d4f",
    text: "No KYC Exchange",
  },
  "high risk jurisdiction": {
    color: "#880d4f",
    text: "Sanctioned Jurisdiction",
  },
  ico: {
    color: "#c4c9ea",
    text: "ICO",
  },
  mixing: {
    color: "#e82463",
    text: "Mixing",
  },
  exchange: {
    color: "#5c6bbe",
    text: "Exchange",
  },
  "p2p exchange": {
    color: "#4a148c",
    text: "P2P Exchange",
  },
  "personal wallet": { color: "#42a5f5", text: "Personal Wallet", desc: null },
  "hosted wallet": {
    color: "#1f75d0",
    text: "Hosted Wallet",
  },
  "merchant services": {
    color: "#ffbf10",
    text: "Merchant Services",
  },
  "mining pool": {
    color: "#4ead51",
    text: "Mining Pool",
  },
  mining: {
    color: "#4ead51",
    text: "Mining",
  },
  other: {
    color: "#009688",
    text: "Other",
  },
  sanctions: {
    color: "#e43b38",
    text: "Sanctioned Entity",
  },
  unknown: { color: "#cccccc", text: "Unknown", desc: null },
  uncategorized: { color: "#009688", text: "Uncategorized", desc: null },
  unidentified: { color: "#009688", text: "Unidentified", desc: null },
  "unnamed service": { color: "#455A63", text: "Unnamed Service", desc: null },
  unspent: { color: "#617D8B", text: "Unspent", desc: null },
  address: { color: "#607d8b", text: "Address", desc: null },
  "fraud shop": {
    color: "#ed810e",
    text: "Fraud Shop",
  },
  "illicit actor-org": {
    color: "#880d4f",
    text: "Illicit Actor-Org",
  },
  "infrastructure as a service": {
    color: "#880d4f",
    text: "Infrastructure as a Service",
  },
  "decentralized exchange": {
    color: "#7C609B",
    text: "Decentralized Exchange",
  },
  "lending contract": {
    color: "#a5a3e0",
    text: "Lending",
  },
  "smart contract": {
    color: "#AA6AD8",
    text: "Smart Contract",
  },
  "token smart contract": {
    color: "#4b6651",
    text: "Token Smart Contract",
  },
  "stolen funds": {
    color: "#79564a",
    text: "Stolen Funds",
  },
  "protocol privacy": {
    color: "#e82463",
    text: "Protocol Privacy",
  },
  // Renamed and new categories
  "sanctioned jurisdiction": {
    color: "#880d4f",
    text: "Sanctioned Jurisdiction",
  }, // rename of High Risk Jurisdiction
  lending: { color: "#a5a3e0", text: "Lending" }, // rename of Lending Contract
  "sanctioned entity": { color: "#e43b38", text: "Sanctioned Entity" }, // rename of Sanctions
  // New DeFi and cybercrime categories
  bridge: { color: "#AA6AD8", text: "Bridge" },
  malware: { color: "#8B1B1B", text: "Malware" },
  "nft platform - collection": {
    color: "#7C609B",
    text: "NFT Platform - Collection",
  },
  "seized funds": { color: "#207923", text: "Seized Funds" },
  "special measures": { color: "#880d4f", text: "Special Measures " },
  "online pharmacy": { color: "#FF7573", text: "Online Pharmacy" },
};

export const categoryOptions = [
  { value: "atm", key: "atm", text: "ATM" },
  {
    value: "child abuse material",
    key: "child abuse material",
    text: "Child Abuse Material",
  },
  { value: "darknet market", key: "darknet market", text: "Darknet Market" },
  { value: "erc20 token", key: "erc20 token", text: "ERC20 Token" },
  {
    value: "ethereum contract",
    key: "ethereum contract",
    text: "Ethereum Contract",
  },
  { value: "exchange", key: "exchange", text: "Exchange" },
  { value: "gambling", key: "gambling", text: "Gambling" },
  {
    value: "high risk exchange",
    key: "high risk exchange",
    text: "High Risk Exchange",
  },
  {
    value: "high risk jurisdiction",
    key: "high risk jurisdiction",
    text: "Sanctioned Jurisdiction",
  },
  { value: "hosted wallet", key: "hosted wallet", text: "Hosted Wallet" },
  { value: "ico", key: "ico", text: "ICO" },
  {
    value: "merchant services",
    key: "merchant services",
    text: "Merchant Services",
  },
  { value: "mining", key: "mining", text: "Mining" },
  { value: "mining pool", key: "mining pool", text: "Mining Pool" },
  { value: "mixing", key: "mixing", text: "Mixing" },
  { value: "other", key: "other", text: "Other" },
  { value: "p2p exchange", key: "p2p exchange", text: "P2P Exchange" },
  { value: "ransomware", key: "ransomware", text: "Ransomware" },
  { value: "sanctions", key: "sanctions", text: "Sanctioned Entity" },
  { value: "scam", key: "scam", text: "Scam" },
  { value: "stolen funds", key: "stolen funds", text: "Stolen Funds" },
  { value: "stolen bitcoins", key: "stolen bitcoins", text: "Stolen Bitcoins" },
  {
    value: "terrorist financing",
    key: "terrorist financing",
    text: "Terrorist Financing",
  },
  {
    value: "protocol privacy",
    key: "protocol privacy",
    text: "Protocol Privacy",
  },
  // Renamed and new categories
  {
    value: "sanctioned jurisdiction",
    color: "#880d4f",
    text: "Sanctioned Jurisdiction", // rename of High Risk Jurisdiction
  },
  {
    value: "special measures",
    color: "#880d4f",
    text: "Special Measures",
  },
  { value: "lending", color: "#a5a3e0", text: "Lending" }, // rename of Lending Contract
  { value: "sanctioned entity", color: "#e43b38", text: "Sanctioned Entity" }, // rename of Sanctions
  // New DeFi and cybercrime categories
  { value: "bridge", color: "#AA6AD8", text: "Bridge" },
  { value: "malware", color: "#8B1B1B", text: "Malware" },
  {
    value: "nft platform - collection",
    color: "#7C609B",
    text: "NFT Platform - Collection",
  },
  { value: "seized funds", color: "#207923", text: "Seized Funds" },
  { value: "online pharmacy", color: "#FF7573", text: "Online Pharmacy" },
  { value: "unnamed service", key: "unnamed service", text: "Unnamed Service" },
  { value: "_empty_", key: "_empty_", text: "Unindentified" },
];

export const alertTypeOptions = [
  {
    value: "Child Abuse Material Exposure",
    key: "Child Abuse Material Exposure",
    text: "Child Abuse Material Exposure",
  },
  {
    value: "Risky Exposure (%)",
    key: "Risky Exposure (%)",
    text: "Risky Exposure (%)",
  },
  {
    value: "Sanctions Exposure",
    key: "Sanctions Exposure",
    text: "Sanctions Exposure",
  },
  {
    value: "Terrorist Financing Exposure",
    key: "Terrorist Financing Exposure",
    text: "Terrorist Financing Exposure",
  },
];

const alertCategories = [
  "child abuse material",
  "darknet market",
  "gambling",
  "high risk exchange",
  "no kyc exchange",
  "sanctioned jurisdiction",
  "mixing",
  "ransomware",
  "sanctioned entity",
  "scam",
  "stolen funds",
  "terrorist financing",
];

export const riskyCategories = [
  "child abuse material",
  "darknet market",
  "gambling",
  "high risk exchange",
  "no kyc exchange",
  "sanctioned jurisdiction",
  "mixing",
  "p2p exchange",
  "ransomware",
  "malware",
  "sanctioned entity",
  "special measures",
  "scam",
  "scams",
  "stolen bitcoins",
  "seized funds",
  "terrorist financing",
  "stolen funds",
  "protocol privacy",
  "fraud shop",
  "illicit actor-org",
  "infrastructure as a service",
];

export const unidentifiedCategory = "unidentified";

export const excludedCategories = ["stolen ether", "unnamed service"];

export const getCategoryText = (category) =>
  categories[category] ? categories[category].text : category;

export const getCategoryColor = (category) =>
  categories[category] ? categories[category].color : "#009688";

export const getCategoryDesc = (category) =>
  categories[category]?.desc || "No description";

export const alertCategoryOptions = categoryOptions.filter((category) =>
  alertCategories.includes(category.key)
);

export const categoryMapper = (value) => ({
  value,
  text: getCategoryText(value),
  isRisky: value === "unidentified" ? null : riskyCategories.includes(value),
});

export const chartTitleMap = {
  deposit: "Receiving Exposure",
  withdrawal: "Sending Exposure",
};

export const statusOptions = [
  {
    text: "Open",
    value: "OPEN",
    key: "OPEN",
  },
  {
    text: "Closed",
    value: "CLOSED",
    key: "CLOSED",
  },
];

export const alertStatusOptions = [
  {
    text: "Unreviewed",
    value: "UNREVIEWED",
    key: "Unreviewed",
  },
  {
    text: "In Review",
    value: "IN REVIEW",
    key: "In Review",
  },
  {
    text: "Dismissed",
    value: "DISMISSED",
    key: "Dismissed",
  },
  {
    text: "Flagged",
    value: "FLAGGED",
    key: "Flagged",
  },
];

// Misc. helper functions
export const formatDate = (date, displayTime) => {
  if (!date) {
    return "—";
  }
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    ...(displayTime && { hour: "numeric" }),
    ...(displayTime && { minute: "numeric" }),
    timeZone: "UTC",
  };

  return new Intl.DateTimeFormat(navigator.language, options).format(
    new Date(date)
  );
};

export const momentFormatDate = (date, format) =>
  date ? moment.utc(date, undefined).format(format) : "—";

export const getTimeRange = (num, type) => {
  const timestampGt = moment()
    .subtract(num, type)
    .add(1, "day")
    .format("YYYY-MM-DDT00:00:00");
  const timestampLt = moment().format("YYYY-MM-DDT23:59:59");
  return queryString.stringify({
    timestamp_gt: timestampGt,
    timestamp_lt: timestampLt,
  });
};

export const getDefaultDateRangeForCharts = (yearsBack = 3) => {
  const defaultEndDate = Math.floor(Date.now() / 1000);
  const leapDay = new Date(new Date().getFullYear(), 1, 29) === 1 ? 1 : 0;
  const defaultStartDate = Math.floor(
    defaultEndDate - (365 * yearsBack + leapDay) * 86400
  );

  return [defaultStartDate, defaultEndDate];
};

export const formatNumber = (value, format) => {
  const num = parseFloat(value);
  if (Number.isNaN(num)) {
    return "—";
  }

  if (!(num >= 0.0005 || num === 0)) {
    return "< .001";
  }

  switch (format) {
    case "currency":
      return new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "USD",
      }).format(num);
    case "asset":
      return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 6,
      }).format(num);
    case "integer":
      return new Intl.NumberFormat(navigator.language).format(num);
    case "percent":
      return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 3,
      }).format(num);
    case "currencyNoDecimal":
      return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "USD",
      }).format(num);
    default:
      return num;
  }
};

export const generateTimeSeriesRange = (
  time,
  index,
  end,
  resolution,
  numPoints
) => {
  const currentTimestamp = moment.utc(time);
  const endTimestamp = moment.utc(end * 1000);

  if (numPoints === 1) {
    return `${currentTimestamp.format("ll")} - ${endTimestamp.format("ll")}`;
  }
  if (resolution === "WEEK") {
    if (index === 0) {
      return `${currentTimestamp.format("ll")} - ${currentTimestamp
        .endOf("isoWeek")
        .format("ll")}`;
    }
    if (index === numPoints - 1) {
      return `${currentTimestamp
        .startOf("isoWeek")
        .format("ll")} - ${endTimestamp.format("ll")}`;
    }
    return `${currentTimestamp
      .startOf("isoWeek")
      .format("ll")} - ${currentTimestamp.endOf("isoWeek").format("ll")}`;
  }
  if (resolution === "DAY") {
    return `${currentTimestamp.startOf("day").format("ll")}`;
  }
  return "";
};

export const getUTCTimestamp = (localTimestamp) => {
  // Get inverted utcOffset in minutes
  const utcOffset = moment.unix(localTimestamp).utcOffset();
  return moment
    .unix(localTimestamp)
    .startOf("day")
    .add(utcOffset, "minutes")
    .unix();
};

export const filterObjectEntry = (obj, key) =>
  Object.keys(obj)
    .filter((param) => !param.includes(key))
    .reduce((acc, curr) => ({ ...acc, [curr]: obj[curr] }), {});

export const combineParams = (param1, param2) =>
  `?${queryString.stringify({
    ...queryString.parse(param1),
    ...queryString.parse(param2),
  })}`;

export const removeSortAndOffset = (key, resourceName) =>
  queryString.stringify(
    filterObjectEntry(
      filterObjectEntry(
        queryString.parse(key.slice(resourceName.length + 1)),
        "offset"
      ),
      "sort"
    )
  );

export const stringifyQueryString = (paramString, defaultSort) => {
  const params = queryString.parse(paramString);
  if (!params.sort) {
    params.sort = `${defaultSort} desc`;
  }
  if (!params.offset) {
    params.offset = "0";
  }
  return `?${queryString.stringify(params)}`;
};

export const generateNewFilter = (currentFilter) =>
  currentFilter.startsWith("indirect")
    ? currentFilter.slice(8).toLowerCase()
    : `indirect${
        currentFilter.charAt(0).toUpperCase() + currentFilter.slice(1)
      }`;

function* rangeGenerator(min = 0, max, step = 1) {
  // eslint-disable-next-line no-param-reassign
  if (max === undefined) [max, min] = [min, 0];
  for (let i = min; i < max; i += step) yield i;
}

export const range = (count) =>
  Array.from(rangeGenerator(count)).map((i) => i + 1);

const benchmarkSeriesColors = {
  "Top Exchanges": "#000000",
  "No KYC Exchanges": "#FF0000",
  exposure: "rgb(119,162,225)",
};

export const getChartConfig = (chartType, options) => {
  const { asset, address, resolution, startDate, endDate, serviceName } =
    options;
  const utcTimestampStart = startDate; // ? getUTCTimestamp(startDate) : null;
  const utcTimestampEnd = endDate; // ? getUTCTimestamp(endDate) : null;
  if (chartType === "topVolume") {
    return {
      chartType,
      title: "Transfer Volume per Category",
      tooltip: `Total transfers directly sent from and received by ${serviceName}.`,
      // eslint-disable-next-line max-len
      url: `/api/ix/private/clusters/${asset}/${address}/reports/exposure?resolution=${resolution}${
        utcTimestampStart ? `&startDate=${utcTimestampStart}` : ""
      }${utcTimestampEnd ? `&endDate=${utcTimestampEnd - 1}` : ""}`,
      responseMapper: (response) => ({
        pointStart: moment(response.data.range.start).unix(),
        pointEnd: moment(response.data.range.end).unix(),
        series: response.data.series
          .map((record) => ({
            ...record,
            name: record.category || "unidentified",
            color: getCategoryColor(record.category || "unidentified"),
          }))
          .sort(
            (a, b) =>
              b.data.reduce((acc, curr) => acc + curr) -
              a.data.reduce((acc, curr) => acc + curr)
          ),
      }),
    };
  }

  if (chartType === "benchmark") {
    const { allCategories, categories } = options;

    return {
      chartType,
      title: "Benchmarking",
      tooltip: `Compare ${serviceName}'s activity to the Top Exchanges and No KYC Exchanges indices.`,
      // eslint-disable-next-line max-len
      url: `/api/ix/private/benchmark/${asset}/${address}?categories=${
        allCategories ? "" : Array.from(categories || []).join(",")
      }${utcTimestampStart ? `&startDate=${utcTimestampStart}` : ""}${
        utcTimestampEnd ? `&endDate=${utcTimestampEnd - 1}` : ""
      }`,
      responseMapper: (response) => ({
        pointStart: utcTimestampStart,
        pointEnd: utcTimestampEnd,
        series: response.data.map((record) => ({
          lineWidth: 2,
          color: benchmarkSeriesColors[record.name.replace(/50\s/, "")], // Top 50 Exchanges -> Top Exchanges
          name:
            record.name === "exposure"
              ? serviceName
              : record.name.replace(/50\s/, ""), // Top 50 Exchanges -> Top Exchanges
          dashStyle: record.name === "exposure" ? "Solid" : "Dash",
          data: record.dataPoints
            .map((dataPoint) => ({
              x: dataPoint.timestamp * 1000,
              y: dataPoint.value,
            }))
            .sort((a, b) => a.x - b.x),
        })),
      }),
    };
  }

  throw new Error("Unknown chart type!");
};

export function simpleReducer(currentState, newState) {
  return { ...currentState, ...newState };
}

export function newLine2Br(string) {
  const parts = string.split(/\n+/);
  if (parts.length > 1) {
    return parts.map((part) => (
      <>
        {part}
        <br />
      </>
    ));
  }
  return string;
}

export function flatten(array) {
  if (!array) return [];
  if (Array.isArray(array)) {
    let result = [];
    array.forEach((value) => {
      if (Array.isArray(value)) {
        result = result.concat(flatten(value));
      } else {
        result.push(value);
      }
    });
    return result.filter((value) => value);
  }
  return [array];
}

export function dedupeArray(ar) {
  const array = flatten(ar);
  return array.filter((value, index, arr) => arr.indexOf(value) === index);
}

export function renderArrayOrEmpty(array, renderItem, empty) {
  return array && array.length > 0 ? array.map(renderItem) : [empty];
}

export function getQuarter(d = new Date()) {
  return Math.floor(d.getMonth() / 3) + 1;
}

export function parseLocalStorageItem(item) {
  try {
    return JSON.parse(localStorage.getItem(item));
  } catch (err) {
    localStorage.removeItem(item);
    return null;
  }
}

export const organizationAccountTypeMap = {
  TRIAL: "trial",
  TRAINING: "training",
};
