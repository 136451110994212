import { getToken } from './request';

const isTokenValid = () => {
  const expiration = localStorage.getItem('tokenExpiration');
  return expiration && Date.now() < parseInt(expiration, 10);
};

const getCapabilities = async () => {
  if (isTokenValid()) {
    const capabilities = localStorage.getItem('capabilities');
    return JSON.parse(capabilities);
  }
  return getToken()
    .then((res) => {
      return Promise.resolve(res)    
    });
};

export default getCapabilities;
