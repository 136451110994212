/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import { jsx, css } from "@emotion/core";
import { message } from "@chainalysis/react-ui";
import RouterPropTypes from "react-router-prop-types";
import { SimpleDataProvider } from "./providers";
import { DynamicTable } from ".";
import { xSmallPageLimit } from "../utils/constants";
import { serviceAlertsHeaderMap, serviceAlertsDataMap } from "../utils/table";
import { useSelection, useBasicAccount } from "../utils/hooks";
import { SectionHeader } from "./styled/text";

const selectionKeys = [
  "rootaddress",
  "category",
  "weekstartdate",
  "weekenddate",
];
const getSelectedRowKey = (record) =>
  record
    ? selectionKeys.reduce((prev, key) => prev + record[key], "")
    : undefined;

const tableCss = css`
  .ant-table {
    table {
      border-collapse: collapse;
      .ant-table-selection-column {
        * {
          display: none;
        }
      }
      .ant-table-row-selected {
        border: 2px solid #293972;
        td {
          background: #f4f7ff;
        }
      }
    }
  }
`;

message.config({ maxCount: 1 });

const ServiceAlertsTable = ({
  history,
  asset,
  address,
  profileData,
  navigationId,
}) => {
  const isWatchedService = profileData && profileData.data.isWatchedService;
  const isBasicAccount = useBasicAccount();
  const {
    selection: { selectedAlert },
    updateSelection,
  } = useSelection();

  const handleRowClick = (record) => {
    const selectedRowKey = getSelectedRowKey(selectedAlert);
    const thisSelectedRowKey = getSelectedRowKey(record);
    if (selectedRowKey !== thisSelectedRowKey) {
      message.info({
        content: "Alert filters applied to transfer data",
        key: "serviceAlertsTableFilterMessage",
      });
    }

    updateSelection({ selectedAlert: record }, true);
  };

  const onRowClick = () => {
    if (isBasicAccount) {
      return false;
    }
    return (record) => (record.hasDrilldown ? handleRowClick(record) : false);
  };

  return !isWatchedService && !isBasicAccount ? null : (
    <SimpleDataProvider
      pageLimit={xSmallPageLimit}
      resourceName={`alerts/${asset}/${address}${isBasicAccount ? "/any" : ""}`}
      name="alerts"
      defaultSort="weekenddate"
      defaultSortDirection="desc"
      history={history}
    >
      {(services) => (
        <DynamicTable
          css={tableCss}
          iconType="warning"
          pageLimit={xSmallPageLimit}
          hasCheckbox
          checkboxType="none"
          selectedRowKey={getSelectedRowKey(selectedAlert)}
          tableHeader={
            <SectionHeader
              type="warning"
              text={isBasicAccount ? "Exposure Highlights" : "Alerts"}
              id={navigationId}
            />
          }
          dataMap={serviceAlertsDataMap}
          handleRowClick={onRowClick()}
          columnHeaderMap={serviceAlertsHeaderMap(isBasicAccount)}
          headerCells={[
            "category",
            "weekstartdate",
            "weekenddate",
            "actualvalue",
            "alertrule",
          ]}
          rowKey={selectionKeys}
          emptyText={`This service has no ${
            isBasicAccount ? "exposure highlights" : "alerts"
          }`}
          isProfile
          paginationButtonStyle="arrows"
          {...services}
        />
      )}
    </SimpleDataProvider>
  );
};

ServiceAlertsTable.propTypes = {
  history: RouterPropTypes.history,
  asset: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  profileData: PropTypes.objectOf(PropTypes.any).isRequired,
  navigationId: PropTypes.string,
};

ServiceAlertsTable.defaultProps = {
  history: undefined,
  navigationId: undefined,
};

export default ServiceAlertsTable;
