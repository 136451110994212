import React from 'react';
import PropTypes from 'prop-types';
import { simpleReducer } from 'utils';

const initSelectionState = { selectedAlert: null, notification: null };

const SelectionContext = React.createContext({
  selection: { selectedAlert: null, notification: {} },
  resetSelections: false,
  updateSelection: () => false,
});

const SelectionContextProvider = ({ children }) => {
  const [selection, setSelection] = React.useReducer(simpleReducer, initSelectionState);
  const [resetSelections, setResetSelections] = React.useState(false);

  const updateSelection = (newSelection, reset = false) => {
    const { selectedAlert, notification } = newSelection;
    const { selectedAlert: currentSelectedAlert } = selection;

    if (!Object.is(selectedAlert, currentSelectedAlert)) {
      setSelection({ selectedAlert, notification });
      setResetSelections(false);
    } else {
      setSelection({ selectedAlert: null, notification: null });
      setResetSelections(reset);
    }
  };

  return (
    <SelectionContext.Provider value={{
      selection,
      resetSelections,
      updateSelection,
    }}
    >{children}
    </SelectionContext.Provider>
  );
};

SelectionContextProvider.propTypes = { children: PropTypes.node.isRequired };

export {
  SelectionContext,
  SelectionContextProvider,
};
