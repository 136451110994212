import React from 'react';
import { simpleReducer } from 'utils';
import PropTypes from 'prop-types';
import request from 'utils/request';
import { Typography, Button, Loader } from '@chainalysis/react-ui';
import { LoadingContext } from './LoadingContextProvider';

const ChartDataProvider = ({ chartConfig, skipRequestIf, children }) => {
  const { isLoading } = React.useContext(LoadingContext);
  const [
    { loading, error, chartData, tryAgain },
    setState,
  ] = React.useReducer(simpleReducer, {
    loading: false,
    error: false,
    chartData: {},
    tryAgain: false,
  });

  React.useLayoutEffect(() => {
    setState({ loading: isLoading });
  }, [isLoading]);

  const fetchData = async () => {
    setState({ loading: true, error: false });

    if (skipRequestIf) {
      return setState({ loading: false, error: false, chartData: {} });
    }

    let result = {};
    try {
      result = await request({
        method: 'GET',
        url: chartConfig.url,
      });
      const apiResponse = {
        chartData: chartConfig.responseMapper(result),
        loading: false,
      };
      return setState({ ...apiResponse });
    } catch (err) {
      return setState({ error: true, loading: false });
    }
  };

  React.useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [tryAgain, chartConfig]);

  const tryAgainSelect = () => {
    setState({ tryAgain: !tryAgain });
  };

  return (error ? (
    <div css={{ padding: '8.5rem 7rem' }}>
      <Typography.Paragraph strong css={{ textAlign: 'center' }}>
        Sorry, something went wrong. <Button onClick={() => tryAgainSelect()}>Try Again</Button>
      </Typography.Paragraph>
    </div>
  ) : loading ? (
    <div css={{ padding: '8.5rem 7rem' }}>
      <Loader description="Generating Chart" />
    </div>
  ) : (
    children({ chartData })
  )
  );
};

ChartDataProvider.propTypes = {
  chartConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  skipRequestIf: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

ChartDataProvider.defaultProps = { skipRequestIf: false };

export default ChartDataProvider;
