/** @jsx jsx */
/** @jsxFrag React.Fragment */
// Use this component to display tabular data with filtering, sorting and pagination.
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Empty,
  Loader,
  Panel,
  TableCount,
  Row,
  Col,
  Typography,
  Icon,
  ActionPanel,
  PaginationBar,
  Divider,
} from "@chainalysis/react-ui";
import { jsx } from "@emotion/core";
import { FilterMenu } from ".";
import { Spacer } from "./styled/ui";
import { renderTableRow } from "../utils/table";
import { breakpoints } from "../utils/constants";

const titleCss = {
  marginBottom: "0!important",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const DynamicTable = React.memo(
  ({
    sourceData,
    isLoading,
    expandable,
    dataMap,
    columnHeaderMap,
    headerCells,
    tableHeader,
    pageLimit,
    toolbar,
    rowKey,
    handleFilterChange,
    name,
    isProfile,
    resetFilterUrl,
    resourceName,
    isDirectOnly,
    toggleDirectOnly,
    handleFilterOption,
    hasMore,
    handleSort,
    defaultSort,
    emptyText,
    displayCount,
    count,
    documentWidth,
    isTabbedPanel,
    handlePagination,
    handleRowClick,
    queryParams,
    isDefaultTableView,
    resetFilters,
    selectedRowKey,
    hasCheckbox,
    checkboxType,
    displayOnlyTable,
    customFooter,
    hidePagination,
    iconType,
    paginationButtonStyle,
    className,
    isAlertsTable,
  }) => {
    const [{ selectedRowKeys, selectedRows }, handleRowSelectChange] = [
      { selectedRowKeys: [selectedRowKey], selectedRows: [] },
      undefined,
    ];
    const [column, direction] = queryParams.sort
      ? queryParams.sort.includes(" desc")
        ? [queryParams.sort.slice(0, -5), "descend"]
        : [queryParams.sort, "ascend"]
      : [defaultSort, "descend"];
    const columns = headerCells.map((header) => {
      const {
        filterName,
        textAlign,
        title,
        isSortable,
        filters,
        filterMultiple,
        isFilterable,
        displaySearchBar,
        width,
      } = columnHeaderMap[header];
      return {
        align: textAlign || "left",
        key: header,
        title,
        sortOrder: column === header ? direction : false,
        sorter: isSortable,
        dataIndex: header,
        width,
        className: "data-table-cell",
        render: (text, record) =>
          renderTableRow(columnHeaderMap, dataMap, header, text, record),
        onHeaderCell: (c) => ({
          onClick: () => {
            if (c.sorter) {
              handleSort(c.key);
            }
            return null;
          },
        }),
        filterDropdown: isFilterable ? (
          <FilterMenu
            name={name}
            handleFilterChange={handleFilterChange}
            filterName={filterName}
            queryParams={queryParams}
            filters={filters}
            isDirectOnly={isDirectOnly}
            toggleDirectOnly={toggleDirectOnly}
            handleFilterOption={handleFilterOption}
            displaySearchBar={displaySearchBar}
          />
        ) : undefined,
        filters: isFilterable && filters,
        filterMultiple,
        filteredValue: queryParams[filterName] || null,
      };
    });
    const tableHasNoData = sourceData.length === 0 && isDefaultTableView;

    return (
      <>
        {!displayOnlyTable && (
          <>
            <Row
              type="flex"
              justify="space-between"
              css={{ alignItems: "center" }}
            >
              <Col>
                {typeof tableHeader === "string" ? (
                  <Typography.Title level={3} css={titleCss}>
                    {iconType ? (
                      <Icon
                        type={iconType}
                        style={{ fontSize: "0.75em", marginRight: "0.5rem" }}
                      />
                    ) : null}
                    <span>{tableHeader}</span>
                  </Typography.Title>
                ) : (
                  tableHeader
                )}
              </Col>
              {toolbar && <Col>{toolbar}</Col>}
              {!hidePagination && (
                <Col>
                  <div
                    className="table-pagination"
                    css={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {isAlertsTable && (
                      <>
                        <Link key="rulesButton" to="/alerts/rules">
                          <Button type="primary" icon="book">
                            Rules
                          </Button>
                        </Link>
                        <Spacer direction="horizontal" size="small" />
                        <Divider type="vertical" />
                        <Spacer direction="horizontal" size="small" />
                      </>
                    )}
                    {!tableHasNoData &&
                      (name !== "transfers" ||
                        documentWidth > breakpoints.tablet) && (
                        <PaginationBar
                          buttonStyle={paginationButtonStyle}
                          hasMore={hasMore}
                          offset={parseInt(queryParams.offset, 10)}
                          onChange={handlePagination}
                          pageLimit={pageLimit}
                        />
                      )}
                  </div>
                </Col>
              )}
            </Row>
            <Spacer spaceDirection="vertical" size="medium" />
          </>
        )}
        <Panel
          isTabbedPanel={isTabbedPanel}
          noPaddingWithDivider
          title={displayOnlyTable ? tableHeader : undefined}
        >
          <>
            {isLoading ? (
              <div
                css={{
                  padding: displayOnlyTable
                    ? "14.1rem 0"
                    : isProfile
                    ? "8rem 0"
                    : "12rem 0",
                }}
              >
                <Loader description="Generating Table" />
              </div>
            ) : (
              <Table
                {...expandable}
                className={className}
                rowKey={(record) =>
                  Array.isArray(rowKey)
                    ? rowKey.reduce((prev, curr) => prev + record[curr], "")
                    : record[rowKey]
                }
                scroll={{ x: 900 }}
                size={displayOnlyTable ? "middle" : "default"}
                onRow={
                  handleRowClick
                    ? (record) => ({ onClick: () => handleRowClick(record) })
                    : undefined
                }
                rowClassName={handleRowClick ? () => "clickable" : undefined}
                dataSource={sourceData}
                showHeader={!tableHasNoData}
                columns={columns}
                pagination={false}
                rowSelection={
                  hasCheckbox
                    ? {
                        selectedRowKeys,
                        columnWidth: checkboxType === "none" ? 0 : "",
                        type: checkboxType,
                        onChange: handleRowSelectChange,
                        getCheckboxProps: (record) => ({
                          disabled:
                            record.category === null && record.hasDrilldown,
                        }),
                      }
                    : undefined
                }
                footer={() => (
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      {displayCount && !queryParams.status && (
                        <TableCount name={name} count={count} />
                      )}
                    </div>
                    {displayOnlyTable || hidePagination ? customFooter : null}
                  </div>
                )}
                locale={{
                  emptyText: (
                    <div css={{ padding: isProfile ? "1rem 0" : "12rem 0" }}>
                      <Empty
                        description={
                          <>
                            {isDefaultTableView ? (
                              emptyText ? (
                                <span>{emptyText}</span>
                              ) : (
                                <span>No data to display</span>
                              )
                            ) : (
                              <>
                                <span>{`There are no ${name} that match these filters.`}</span>
                                <br />
                                <br />
                                <Button type="primary" onClick={resetFilters}>
                                  {resetFilters ? (
                                    "Reset all filters"
                                  ) : (
                                    <Link
                                      to={`/${resetFilterUrl || resourceName}`}
                                    >
                                      Reset all filters
                                    </Link>
                                  )}
                                </Button>
                              </>
                            )}
                          </>
                        }
                      />
                    </div>
                  ),
                }}
              />
            )}
          </>
        </Panel>
        {hasCheckbox ? (
          <ActionPanel name={name} selectedRows={selectedRows} />
        ) : undefined}
      </>
    );
  }
);

DynamicTable.propTypes = {
  expandable: PropTypes.objectOf(PropTypes.any),
  count: PropTypes.number,
  emptyText: PropTypes.string.isRequired,
  sourceData: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  dataMap: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  ).isRequired,
  defaultSort: PropTypes.string.isRequired,
  columnHeaderMap: PropTypes.objectOf(PropTypes.object).isRequired,
  headerCells: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  tableHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  pageLimit: PropTypes.number.isRequired,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  selectedRowKey: PropTypes.string,
  toolbar: PropTypes.node,
  displayCount: PropTypes.bool,
  isProfile: PropTypes.bool,
  isAmountUSD: PropTypes.bool,
  resetFilterUrl: PropTypes.string,
  resourceName: PropTypes.string.isRequired,
  hasMore: PropTypes.bool.isRequired,
  documentWidth: PropTypes.number,
  isTabbedPanel: PropTypes.bool,
  isDefaultTableView: PropTypes.bool.isRequired,
  handleRowClick: PropTypes.func,
  displayOnlyTable: PropTypes.bool,
  iconType: PropTypes.string,
  customFooter: PropTypes.node,
  paginationButtonStyle: PropTypes.string,
  checkboxType: PropTypes.string,
  className: PropTypes.string,
};

DynamicTable.defaultProps = {
  expandable: undefined,
  sourceData: { status_display: null },
  tableHeader: undefined,
  toolbar: undefined,
  displayCount: false,
  isProfile: false,
  isAmountUSD: true,
  resetFilterUrl: null,
  count: undefined,
  documentWidth: undefined,
  isTabbedPanel: false,
  expandedRowKey: undefined,
  onExpand: undefined,
  handleRowClick: undefined,
  displayOnlyTable: false,
  customFooter: undefined,
  iconType: undefined,
  selectedRowKey: undefined,
  paginationButtonStyle: undefined,
  checkboxType: undefined,
  className: undefined,
};

export default DynamicTable;
