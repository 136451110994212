import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@chainalysis/react-ui';
import moment from 'moment';

const { RangePicker } = DatePicker;

function disabledDate(current) {
  return current.isBefore(moment.utc().subtract(4, 'years')) || current.isAfter(moment.utc().add(1, 'days'));
}

const DateRangeFilter = React.memo(({ startDate, endDate, handleDateRangeFilter }) => {
  const dateRange = [startDate, endDate];

  return (
    <RangePicker
      allowEmpty={[true, true]}
      ranges={{
        '1w': [moment().subtract(7, 'days').add(1, 'day'), moment()],
        '1m': [moment().subtract(1, 'months').add(1, 'day'), moment()],
        '3m': [moment().subtract(3, 'months').add(1, 'day'), moment()],
        '6m': [moment().subtract(6, 'months').add(1, 'day'), moment()],
        YTD: [moment().startOf('year'), moment()],
        '1y': [moment().subtract(1, 'year').add(1, 'day'), moment()],
      }}
      onCalendarChange={(dates) => {
        handleDateRangeFilter([
          dates?.[0] ? dates[0].unix() : null,
          dates?.[1] ? dates[1].unix() : null,
        ]);
      }}
      onChange={dates =>
        handleDateRangeFilter([
          dates?.[0] ? dates[0].unix() : null,
          dates?.[1] ? dates[1].unix() : null,
        ])
      }
      format="ll"
      placeholder={['Start Date', 'End Date']}
      disabledDate={disabledDate}
      value={[dateRange[0] ? moment.unix(dateRange[0]) : null, dateRange[1] ? moment.unix(dateRange[1]) : null]}
    />
  );
});

DateRangeFilter.propTypes = { handleDateRangeFilter: PropTypes.func.isRequired };

export default DateRangeFilter;
