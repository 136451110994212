/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import { jsx, css } from "@emotion/core";
import PropTypes from "prop-types";
import RouterPropTypes from "react-router-prop-types";

import { Link, withRouter } from "react-router-dom";
import {
  renderArrayOrEmpty,
  getCategoryText,
  formatDate,
  dedupeArray,
  newLine2Br,
} from "utils";
import { useBasicAccount } from "utils/hooks";
import {
  Divider,
  PageHeader,
  Skeleton,
  Tooltip,
  Icon,
  ExpandableList,
} from "@chainalysis/react-ui";

import {
  ServiceName,
  OrgName,
  CategoryName,
  InfoSectionHeader,
  RiskName,
  TableHeader,
} from "./styled/text";
import { Spacer } from "./styled/ui";
import { WatchButton } from ".";
import { ServiceProfileContext } from "./providers/ServiceProfileContextProvider";

const scoreColorMap = {
  "High Risk": "#ED5252",
  High: "#ED5252",
  "Medium Risk": "#F4B12F",
  Medium: "#F4B12F",
  "Low Risk": "#3B990D",
  Low: "#3B990D",
};

const notAvailable = "—";
const subSectionCss = css`
  padding: "0.25rem 0";
`;
const subSectionHeaderCss = css`
  display: "flex";
  aligncontent: "space-between";
`;
const iconCss = css`
  color: #bfbfbf;
  :hover {
    color: #293972;
  }
`;

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
  );
}

function renderRoutes(profile) {
  const routes = [{ path: "/", breadcrumbName: "Services" }];
  if (profile.systemName || profile.name) {
    routes.push({ breadcrumbName: profile.systemName || profile.name });
  }
  return routes;
}

function renderLegalNames(data) {
  const legalNames = data
    ? data.legalNames.filter((name) => name !== null)
    : [];
  return renderArrayOrEmpty(
    legalNames,
    (name) => (
      <div key={name} css={subSectionCss}>
        {name}
      </div>
    ),
    notAvailable
  );
}

function absolutizeUrl(url) {
  if (!/^http[s]*:\/\/[\w]+/i.test(url)) {
    return `//${url}`;
  }
  return url;
}

const expandableListVisibleCount = 5;

const ServiceNameHeader = ({ internalName, orgName }) => {
  if (internalName && orgName) {
    return (
      <>
        <ServiceName data-test-id="name">{internalName}</ServiceName>
        <Spacer spaceDirection="vertical" size="small" />
        <OrgName small data-test-id="orgName">
          {orgName}
        </OrgName>
        <Tooltip
          title="The service has been named by your organization."
          placement="topRight"
          arrowPointAtCenter
        >
          <Icon type="info-circle" theme="filled" css={iconCss} />
        </Tooltip>
      </>
    );
  }
  if (internalName) {
    return <ServiceName data-test-id="name">{internalName}</ServiceName>;
  }
  if (orgName) {
    return (
      <>
        <OrgName>{orgName}</OrgName>
        <Tooltip
          title="This service has been named by your organization but not yet confirmed by Chainalysis."
          placement="topRight"
          arrowPointAtCenter
        >
          <Icon type="info-circle" theme="filled" css={iconCss} />
        </Tooltip>
      </>
    );
  }
  return null;
};

ServiceNameHeader.propTypes = {
  internalName: PropTypes.string,
  orgName: PropTypes.string,
};

ServiceNameHeader.defaultProps = {
  internalName: undefined,
  orgName: undefined,
};

const OverviewPanel = ({
  profileData,
  assets,
  supportedAssets,
  match: { params },
}) => {
  const { isWatchedService, updateWatchedService } = React.useContext(
    ServiceProfileContext
  );

  const isBasicAccount = useBasicAccount();
  const { asset, address } = params;
  const { data, loading } = profileData;

  return (
    <PageHeader
      breadcrumb={{
        itemRender,
        routes: renderRoutes(data),
      }}
    >
      {isBasicAccount || (
        <WatchButton
          isWatched={isWatchedService}
          asset={data?.rootAsset || asset}
          address={data?.rootAddress || address}
          serviceName={data.systemName || data.name}
          orgName={data.orgName}
          onToggleWatch={updateWatchedService}
        />
      )}

      {data.metadata?.rank !== 0 && (
        <div>
          <Skeleton
            paragraph={{ rows: 1 }}
            active
            loading={loading}
            title={false}
          >
            <ServiceNameHeader
              internalName={data.systemName || data.name}
              orgName={data.orgName}
            />
          </Skeleton>
          {data?.metadata?.category && (
            <CategoryName data-test-id="category">
              {getCategoryText(data.metadata.category?.toLowerCase())}
            </CategoryName>
          )}
          {data?.metadata?.url && (
            <div>
              <a
                href={absolutizeUrl(data.metadata.url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.metadata.url}
              </a>
            </div>
          )}
          <Divider type="horizontal" />
          <div>
            <div css={subSectionHeaderCss}>
              <InfoSectionHeader>Risk Rating</InfoSectionHeader>
              {data?.metadata?.updated && (
                <Tooltip
                  title={`Last updated on ${formatDate(data.metadata.updated)}`}
                >
                  <Icon type="clock-circle" css={iconCss} />
                </Tooltip>
              )}
            </div>
            <Spacer spaceDirection="vertical" size="small" />
            {data?.score ? (
              <RiskName color={scoreColorMap[data.score]}>
                {data.score}
              </RiskName>
            ) : (
              notAvailable
            )}
          </div>
          <Divider type="horizontal" />
          <Skeleton
            paragraph={{ rows: 3 }}
            loading={loading}
            title={false}
            active
            key="legalNames"
          >
            <div css={subSectionHeaderCss}>
              <InfoSectionHeader>Overview</InfoSectionHeader>
              {data?.metadata?.updated && (
                <Tooltip
                  title={`Last updated on ${formatDate(data.metadata.updated)}`}
                >
                  <Icon type="clock-circle" css={iconCss} />
                </Tooltip>
              )}
            </div>
            <Spacer spaceDirection="vertical" size="small" />
            <TableHeader>Legal Name</TableHeader>
            <div css={subSectionCss}>{renderLegalNames(data.metadata)}</div>
          </Skeleton>
          <Spacer spaceDirection="vertical" size="large" />
          <Skeleton
            paragraph={{ rows: 1 }}
            loading={loading}
            active
            title={false}
            key="offices"
          >
            <TableHeader data-test-id="headquarters-header">
              Headquarters
            </TableHeader>
            <div css={subSectionCss}>
              {data.metadata?.hqAddress || notAvailable}
            </div>
            <Spacer spaceDirection="vertical" size="large" />
            <TableHeader>Country of Incorporation</TableHeader>
            <div css={subSectionCss}>
              {data.metadata?.hqCountry || notAvailable}
            </div>
            <Spacer spaceDirection="vertical" size="large" />
            <TableHeader>Place of Incorporation</TableHeader>
            <div css={subSectionCss}>
              {data.metadata?.incorporationAddress || notAvailable}
            </div>
            <Spacer spaceDirection="vertical" size="large" />
            <TableHeader>Other office countries</TableHeader>
            <div css={subSectionCss}>
              {renderArrayOrEmpty(
                data.metadata?.otherOfficeCountries,
                (office) => (
                  <div key={office}>{office}</div>
                ),
                notAvailable
              )}
            </div>
          </Skeleton>
          <Spacer spaceDirection="vertical" size="large" />
          <Skeleton
            paragraph={{ rows: 1 }}
            loading={profileData.loading}
            active
            title={false}
            key="fiat"
          >
            <TableHeader>Fiat</TableHeader>
            <div css={subSectionCss}>
              {(data?.metadata?.fiatWithdrawals &&
                data?.metadata?.fiatWithdrawals.length > 0) ||
              (data?.metadata?.fiatDeposits &&
                data?.metadata?.fiatDeposits.length > 0) ? (
                <ExpandableList
                  data={dedupeArray([
                    data.metadata.fiatWithdrawals,
                    data.metadata.fiatDeposits,
                  ])}
                  limit={expandableListVisibleCount}
                />
              ) : (
                notAvailable
              )}
            </div>
            <Spacer spaceDirection="vertical" size="large" />
            <TableHeader>Fiat Withdrawal Methods</TableHeader>
            <div css={subSectionCss}>
              {(data?.metadata?.fiatWithdrawalMethods &&
                data?.metadata?.fiatWithdrawalMethods.join(", ")) ||
                notAvailable}
            </div>
            <Spacer spaceDirection="vertical" size="large" />
            <TableHeader>Other Withdrawal Methods</TableHeader>
            <div css={subSectionCss}>
              {(data?.metadata?.otherWithdrawalMethods &&
                data?.metadata?.otherWithdrawalMethods.join(", ")) ||
                notAvailable}
            </div>
          </Skeleton>
          <Spacer spaceDirection="vertical" size="large" />
          {isBasicAccount || [
            <Skeleton
              paragraph={{ rows: 1 }}
              loading={loading}
              active
              title={false}
              key="assets"
            >
              <TableHeader>Assets</TableHeader>
              <div css={subSectionCss}>
                {assets && assets.data ? (
                  <ExpandableList
                    data={assets.data.map((obj) => obj.asset)}
                    limit={expandableListVisibleCount}
                  />
                ) : (
                  notAvailable
                )}
              </div>
            </Skeleton>,
            <Spacer
              spaceDirection="vertical"
              size="large"
              key="assets-stablecoins-spacer"
            />,
            <Skeleton
              paragraph={{ rows: 1 }}
              loading={supportedAssets.loading}
              active
              title={false}
              key="stableCoins"
            >
              <TableHeader>Stablecoins</TableHeader>
              <div css={subSectionCss}>
                {supportedAssets?.data?.stableCoins?.length > 0 ? (
                  <ExpandableList
                    data={supportedAssets?.data?.stableCoins}
                    limit={expandableListVisibleCount}
                  />
                ) : (
                  notAvailable
                )}
              </div>
            </Skeleton>,
            <Spacer
              spaceDirection="vertical"
              size="large"
              key="stablecoins-privacycoins-spacer"
            />,
            <Skeleton
              paragraph={{ rows: 1 }}
              loading={loading}
              active
              title={false}
              key="privacyCoins"
            >
              <TableHeader>Privacy Coins</TableHeader>
              <div css={subSectionCss}>
                {data?.metadata?.privacyCoins &&
                data?.metadata?.privacyCoins.length > 0 ? (
                  <ExpandableList
                    data={data?.metadata?.privacyCoins}
                    limit={expandableListVisibleCount}
                  />
                ) : (
                  notAvailable
                )}
              </div>
            </Skeleton>,
            <Spacer
              spaceDirection="vertical"
              size="medium"
              key="privacycoins-tradingpairs-spacer"
            />,
            <Skeleton
              paragraph={{ rows: 1 }}
              loading={supportedAssets.loading}
              active
              title={false}
              key="tradingPairs"
            >
              <TableHeader>Trading Pairs</TableHeader>
              <div css={subSectionCss}>
                {supportedAssets &&
                supportedAssets.data.tradingPairs &&
                supportedAssets.data.tradingPairs.length > 0 ? (
                  <ExpandableList
                    data={supportedAssets.data.tradingPairs}
                    limit={expandableListVisibleCount}
                  />
                ) : (
                  notAvailable
                )}
              </div>
            </Skeleton>,
          ]}
          <Divider type="horizontal" />
          <Skeleton
            paragraph={{ rows: 1 }}
            loading={loading}
            active
            title={false}
            key="registration"
          >
            <TableHeader>Registrations</TableHeader>
            {data?.metadata?.registrationNames &&
            data?.metadata?.registrationNames.length ? (
              <div css={subSectionCss}>
                {newLine2Br(data?.metadata?.registrationNames.join("\n"))}
              </div>
            ) : (
              <div css={subSectionCss}>{notAvailable}</div>
            )}
          </Skeleton>
        </div>
      )}
    </PageHeader>
  );
};

OverviewPanel.propTypes = {
  profileData: PropTypes.objectOf(PropTypes.any).isRequired,
  assets: PropTypes.objectOf(PropTypes.any).isRequired,
  match: RouterPropTypes.match.isRequired,
  supportedAssets: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(OverviewPanel);
