/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "@chainalysis/react-ui";
import { jsx } from "@emotion/core";
import { Spacer } from "./styled/ui";

const PaginationBar = ({ offset, pageLimit, onChange, hasMore }) => (
  <div css={{ textAlign: "right" }}>
    <Button
      onClick={() => onChange(offset - pageLimit >= 0 ? offset - pageLimit : 0)}
      disabled={!offset || offset === 0}
    >
      Previous
    </Button>
    <Spacer spaceDirection="horizontal" size="small" />
    <Button
      onClick={() => onChange(offset ? offset + pageLimit : pageLimit)}
      disabled={!hasMore}
    >
      Next
    </Button>
  </div>
);

PaginationBar.propTypes = {
  offset: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageLimit: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default PaginationBar;
