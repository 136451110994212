const methods = ['add', 'clear', 'delete', 'entries', 'forEach', 'has', 'keys', 'values'];

function error(propName, componentName, property, condition) {
  return new Error([
    'Invalid prop `',
    propName,
    '` supplied to `',
    componentName,
    '`. Must be a Set. (`',
    propName,
    '.',
    property,
    '` was not ',
    condition,
    '.)',
  ].join(''));
}

function CustomSet(props, propName, componentName) {
  const s = props[propName];
  if (s == null) return null; // null or undefined

  if (typeof s.size !== 'number') {
    return error(propName, componentName, 'size', 'a number');
  }

  methods.forEach((method) => {
    if (typeof s[method] !== 'function') {
      return error(propName, componentName, method, 'a function');
    }
    return true;
  });

  return null;
}

CustomSet.isRequired = function isRequired(props, propName, componentName) {
  if (props[propName] == null) {
    // null or undefined
    return new Error(`Required prop \`${propName}\` was not specified in ${componentName}.`);
  }

  return CustomSet(props, propName, componentName);
};

export { CustomSet };
