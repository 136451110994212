/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import { withRouter } from "react-router-dom";
import hcnd from "highcharts/modules/no-data-to-display";
import isEqual from "lodash/isEqual";
import { jsx } from "@emotion/core";
import { breakpoints } from "utils/constants";
import { formatNumber, generateTimeSeriesRange } from "utils";
import { CustomSet } from "utils/customPropTypes";

if (!Highcharts.Chart.prototype.hasData) {
  hcnd(Highcharts);
}

const collapseSeriesThreshold = 6;
const collapsedCategory = {
  category: "Additional Categories",
  name: "additional categories",
};

const collapseSeries = (series, threshold) =>
  series.slice(0, threshold).concat([
    series.slice(threshold).reduce((acc, x) => {
      const data = acc.data ? x.data.map((d, i) => d + acc.data[i]) : x.data;
      return { ...acc, data };
    }, collapsedCategory),
  ]);

class TransferVolumeSeriesChart extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      pointStart: PropTypes.number,
      pointEnd: PropTypes.number,
      series: PropTypes.array,
    }),
    selected: CustomSet,
    noDataMessage: PropTypes.string.isRequired,
    resolution: PropTypes.string.isRequired,
    wideChart: PropTypes.bool.isRequired,
  };

  static defaultProps = { data: undefined, selected: undefined };

  constructor(props) {
    super(props);
    this.chart = null;
  }

  componentDidMount() {
    const { data, selected, resolution } = this.props;
    this.chart = this.createChart(
      data.series,
      data.pointStart,
      data.pointEnd,
      selected,
      resolution
    );
  }

  componentDidUpdate(prevProps) {
    const { data, selected, resolution, wideChart } = this.props;

    if (!(data && data.series)) {
      return;
    }

    const currentSelected = selected || new Set();
    const prevPropsSelected = prevProps.selected || new Set();
    const prevPropsWideChart = prevProps.wideChart || false;

    if (!isEqual(currentSelected, prevPropsSelected)) {
      this.chart = this.createChart(
        data.series,
        data.pointStart,
        data.pointEnd,
        selected,
        resolution
      );
    } else if (!isEqual(prevPropsWideChart, wideChart)) {
      this.chart.reflow();
    }
  }

  createChart = (series, pointStart, pointEnd, selected, resolution) =>
    Highcharts.chart(
      "time-series-container",
      {
        chart: {
          type: "area",
          showAxes: true,
        },
        lang: {
          noData: this.props.noDataMessage,
          thousandsSep: ",",
        },
        noData: {
          useHTML: true,
          style: {
            fontWeight: "normal",
            fontSize: "16px",
            fontFamily: `BlinkMacSystemFont, -apple-system, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans','Droid Sans',
          'Helvetica Neue', Helvetica, Arial, sans-serif`,
          },
        },
        credits: { enabled: false },
        title: undefined,
        xAxis: {
          type: "datetime",
          tickInterval: 24 * 3600 * 1000 * (resolution === "WEEK" ? 7 : 1),
        },
        yAxis: {
          min: 0,
          title: {
            text: `${
              resolution === "WEEK" ? "Weekly" : "Daily"
            } Transfer Volume (USD)`,
          },
          labels: {
            enabled: true,
            formatter() {
              return formatNumber(this.value, "currencyNoDecimal");
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: { maxWidth: breakpoints.xs },
              chartOptions: { yAxis: { labels: { enabled: false } } },
            },
            {
              condition: { minWidth: breakpoints.small },
              chartOptions: { yAxis: { labels: { enabled: true } } },
            },
          ],
        },
        legend: { enabled: false },
        plotOptions: {
          area: {
            stacking: "normal",
            lineWidth: 0,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
            },
          },
          series: {
            pointStart: pointStart * 1000,
            pointInterval: resolution === "WEEK" ? 7 : 1,
            pointIntervalUnit: "day",
          },
        },
        series: series
          ? collapseSeries(
              series.filter((category) => selected.has(category.name)),
              collapseSeriesThreshold
            )
          : [],
        tooltip: {
          split: true,
          formatter() {
            const {
              point: { index },
              series: { data },
            } = this.points[0];
            const points = this.points.map((category) =>
              category.y === 0
                ? ""
                : `<span style="fill: ${category.color}">● </span>
            <span>${category.series.name}: </span>
            <span>${formatNumber(
              category.y,
              "currency"
            )} <strong>(${formatNumber(
                    category.percentage,
                    "percent"
                  )}%)</strong></span>`
            );
            return [
              `<span style="font-size: 10px">${generateTimeSeriesRange(
                this.x,
                index,
                pointEnd,
                resolution,
                data.length
              )}</span>`,
              ...(points.join("").length === 0
                ? ["No transfers in the selected categories."]
                : points),
            ];
          },
        },
      },
      (chart) => {
        // Show markers if there's just one point
        if (series && series.length > 0 && series[0].data.length === 1) {
          chart.update(
            { plotOptions: { area: { marker: { enabled: true, radius: 4 } } } },
            true
          );
        }
      }
    );

  render() {
    return <div id="time-series-container" css={{ height: "35vh" }} />;
  }
}

export default withRouter(TransferVolumeSeriesChart);
