/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import {
  Typography,
  Icon,
  Card,
  Skeleton,
  Row,
  Col,
} from "@chainalysis/react-ui";
import RouterPropTypes from "react-router-prop-types";
import { jsx } from "@emotion/core";
import { useRequest } from "utils/hooks";
import { primaryColor } from "utils/constants";

const Watchlist = ({ history }) => {
  const [{ data, isLoading }] = useRequest(
    "/api/ix/private/alerts/watched-services"
  );
  return (
    <>
      <Typography.Title level={3} css={{ color: primaryColor }}>
        <Icon type="star" /> Watchlist
      </Typography.Title>
      <Row gutter={[16, 16]}>
        {isLoading ? (
          [...Array(4)].map((_) => (
            <Col xs={12} md={6}>
              <Card>
                <Skeleton active paragraph={{ rows: 1 }} key={`col-${_}`} />
              </Card>
            </Col>
          ))
        ) : data && data.length !== 0 ? (
          data.map((service) => (
            <Col xs={12} md={6}>
              <Card
                hoverable
                onClick={() =>
                  history.push(
                    `/services/${service.rootAsset}/${service.rootAddress}`
                  )
                }
                css={{ textAlign: "center" }}
              >
                <p
                  css={{ fontSize: "1.2rem", fontWeight: 500, marginBottom: 0 }}
                >
                  {service.serviceName ||
                    service.orgName ||
                    service.rootAddress}
                </p>
                <p>
                  {service.alertCount}{" "}
                  {service.alertCount === 1 ? "ALERT" : "ALERTS"}
                </p>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <p>
              Visit a service profile page to add services to your Watchlist.
            </p>
          </Col>
        )}
      </Row>
    </>
  );
};

Watchlist.propTypes = { history: RouterPropTypes.history.isRequired };

export default Watchlist;
