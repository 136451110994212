import React from 'react';

const useTimeout = (callback, timeout) => {
  React.useEffect(() => {
    const timeoutId = setTimeout(callback, timeout);
    return () => clearTimeout(timeoutId);
  }, [callback, timeout]);
};

export default useTimeout;
