import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withRouter } from 'react-router-dom';
import { Tooltip, Button, Icon } from '@chainalysis/react-ui';
import { useBasicAccount } from 'utils/hooks';
import { WatchButton } from '.';
import { ServiceProfileContext } from './providers/ServiceProfileContextProvider';

const CollapsedOverviewPanel = ({ profileData, onShowMetadata, match: { params } }) => {
  const serviceName = profileData?.data?.systemName || profileData?.data?.name;
  const isBasicAccount = useBasicAccount();

  const {
    isWatchedService,
    updateWatchedService,
  } = React.useContext(ServiceProfileContext);

  const { asset, address } = params;

  return (
    <div css={{ display: 'flex', flexDirection: 'column', padding: '0.75rem', alignItems: 'center' }}>
      {isBasicAccount || (
        <div>
          <WatchButton
            isInCollapsed
            isWatched={isWatchedService}
            asset={profileData?.data?.rootAsset || asset}
            address={profileData?.data?.rootAddress || address}
            serviceName={serviceName}
            orgName={profileData.data.orgName}
            onToggleWatch={updateWatchedService}
          />
        </div>
      )}
      <Tooltip placement="right" title="Show Metadata">
        <Button type="link" size="large" onClick={onShowMetadata}>
          <Icon type="file-text" css={{ fontSize: '1.5em' }} />
        </Button>
      </Tooltip>
    </div>
  );
};

CollapsedOverviewPanel.propTypes = {
  profileData: PropTypes.objectOf(PropTypes.any).isRequired,
  onShowMetadata: PropTypes.func.isRequired,
  match: RouterPropTypes.match.isRequired,
};

export default withRouter(CollapsedOverviewPanel);
