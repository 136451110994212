import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Button, Icon, Menu, Checkbox } from "@chainalysis/react-ui";
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "@emotion/core";
import sortBy from "lodash/sortBy";
import { getCategoryText } from "../utils";
import { MultiSelectItem, MultiSelectHeader } from "./styled";
import { CustomSet } from "../utils/customPropTypes";

const CategoryList = ({ header, options, selectFilters, selectedFilters }) =>
  options.length ? (
    <>
      {header ? <MultiSelectHeader>{header}</MultiSelectHeader> : undefined}
      {options.map((option) => (
        <MultiSelectItem key={option.value}>
          <Checkbox
            checked={selectedFilters.has(option.value)}
            onChange={(e) => {
              selectFilters(e.target.value);
            }}
            value={option.value}
          >
            {option.text}
          </Checkbox>
        </MultiSelectItem>
      ))}
    </>
  ) : null;

CategoryList.propTypes = {
  header: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectFilters: PropTypes.func.isRequired,
  selectedFilters: CustomSet.isRequired,
};

CategoryList.defaultProps = { header: "" };

const MultiFilterSelect = ({
  selectFilters,
  options,
  selectedFilters,
  disabled,
  className,
}) => {
  const [open, setOpen] = useState(false);

  const uncategorizedOptions = sortBy(
    options.filter((option) => option.isRisky === null),
    "value"
  );
  const riskyOptions = sortBy(
    options.filter((option) => option.isRisky),
    "value"
  );
  const nonRiskyOptions = sortBy(
    options.filter((option) => option.isRisky === false),
    "value"
  );

  const buttonText = () => {
    const selected = Array.from(selectedFilters);

    if (selected.length === 0) {
      return "Filter Categories";
    }
    if (selected.length === 1) {
      return getCategoryText(selected[0]);
    }
    if (selected.length === options.length) {
      return "All Categories";
    }
    if (
      selected.length === riskyOptions.length &&
      riskyOptions.every((option) => selected.includes(option.value))
    ) {
      return "Risky Only";
    }
    if (
      selected.length === riskyOptions.length &&
      nonRiskyOptions.every((option) => selected.includes(option.value))
    ) {
      return "Non-Risky Only";
    }

    return "Categories";
  };

  return (
    <Dropdown
      className={className}
      disabled={disabled}
      onVisibleChange={(flag) => setOpen(flag)}
      visible={open}
      trigger={["click"]}
      overlay={
        <Menu css={{ maxHeight: "25rem", overflowY: "scroll" }}>
          <Menu.Item
            onClick={() =>
              selectedFilters && selectedFilters.size === options.length
                ? selectFilters("none")
                : selectFilters("all")
            }
          >
            {selectedFilters && selectedFilters.size === options.length
              ? "Deselect All"
              : "Select All"}
          </Menu.Item>
          <Menu.Item onClick={() => selectFilters("risky")}>
            Select Risky
          </Menu.Item>
          <Menu.Divider />
          <CategoryList
            options={uncategorizedOptions}
            selectFilters={selectFilters}
            selectedFilters={selectedFilters}
          />
          <Menu.Divider />
          <CategoryList
            options={riskyOptions}
            header="Risky"
            selectFilters={selectFilters}
            selectedFilters={selectedFilters}
          />
          <CategoryList
            options={nonRiskyOptions}
            header="Non-risky"
            selectFilters={selectFilters}
            selectedFilters={selectedFilters}
          />
        </Menu>
      }
    >
      {/* Line height 1.5 fixes alignment with Select, default is 1.499 */}
      <Button css={{ lineHeight: 1.5 }}>
        {buttonText()} <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

MultiFilterSelect.propTypes = {
  className: PropTypes.string,
  selectFilters: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedFilters: PropTypes.instanceOf(Set),
  disabled: PropTypes.bool.isRequired,
};

MultiFilterSelect.defaultProps = {
  options: [],
  selectedFilters: null,
  className: undefined,
};

export default MultiFilterSelect;
