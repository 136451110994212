/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import { jsx, css } from "@emotion/core";
import {
  Loader,
  Row,
  Col,
  PaginationBar,
  Empty,
  Table,
} from "@chainalysis/react-ui";
import { TableHeader } from "./styled/text";
import { formatNumber } from "../utils";
import { xSmallPageLimit } from "../utils/constants";
import { renderTableRow, generateRowKey } from "../utils/table";

const cardCss = css`
  margin-bottom: 1rem;
  border: 1px solid #e8e8e8;
  .ant-table-small,
  .ant-table-fixed {
    border: none;
    background: #fbfbfb;
    .ant-table-thead > tr > th {
      border: none;
    }
  }
`;

const cardHeaderCss = css`
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
`;

const CardList = ({
  title,
  isLoading,
  queryParams,
  headers,
  sourceData,
  columnHeaderMap,
  rowKey,
  dataMap,
  hasMore,
  handlePagination,
  pageLimit,
  displayCount,
  emptyText,
  count,
}) => {
  const { offset } = queryParams;

  if (isLoading) {
    return (
      <div css={{ padding: "4rem 0" }}>
        <Loader description="Loading transfers" />
      </div>
    );
  }

  const columns = headers
    .filter((header) => header !== "hash")
    .map((header) => {
      const {
        span,
        width,
        fixed,
        title: headerTitle,
      } = columnHeaderMap[header];
      return {
        render: (text, record) => ({
          children: renderTableRow(
            columnHeaderMap,
            dataMap,
            header,
            text,
            record
          ),
          props: { colSpan: span },
        }),
        title: <TableHeader>{headerTitle}</TableHeader>,
        width,
        fixed,
        dataIndex: header,
        key: header,
        ellipsis: true,
      };
    });

  return count === 0 ? (
    <Empty description={emptyText || "No transfers to display"} />
  ) : (
    <div css={{ marginRight: "1rem", marginLeft: "-2rem" }}>
      <Row type="flex" justify="space-between" css={{ padding: "0.75rem 0" }}>
        <Col>
          <TableHeader>
            {displayCount
              ? `${formatNumber(count, "integer")} ${title}`
              : title}
          </TableHeader>
        </Col>
        <Col>
          <PaginationBar
            pageLimit={pageLimit}
            offset={parseInt(offset, 10)}
            hasMore={hasMore}
            buttonStyle="arrows"
            onChange={handlePagination}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {sourceData.map((record) => (
            <div key={generateRowKey(record, rowKey)} css={cardCss}>
              <div css={cardHeaderCss}>
                {renderTableRow(
                  columnHeaderMap,
                  dataMap,
                  "hash",
                  record.hash,
                  record
                )}
              </div>
              <Table
                rowKey={rowKey}
                pagination={false}
                columns={columns}
                dataSource={[record]}
                size="small"
                scroll={{ x: 900 }}
                bordered={false}
              />
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

CardList.propTypes = {
  isLoading: PropTypes.bool,
  count: PropTypes.number,
  emptyText: PropTypes.string,
  displayCount: PropTypes.bool,
  queryParams: PropTypes.objectOf(PropTypes.any).isRequired,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  hasMore: PropTypes.bool.isRequired,
  handlePagination: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  sourceData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnHeaderMap: PropTypes.objectOf(PropTypes.object).isRequired,
  dataMap: PropTypes.objectOf(PropTypes.func).isRequired,
  pageLimit: PropTypes.number,
};

CardList.defaultProps = {
  pageLimit: xSmallPageLimit,
  isLoading: false,
  count: 0,
  displayCount: false,
  emptyText: undefined,
};

export default CardList;
