import styled from '@emotion/styled';

// https://ant.design/docs/spec/proximity#The-relation-of-vertical-spacing
const formats = {
  small: 0.25,
  medium: 0.5,
  large: 0.75,
};

export const Spacer = styled.div(props =>
  (props.spaceDirection === 'vertical'
    ? {
      boxSizing: 'border-box',
      marginTop: `${formats[props.size] * 2}rem`,
    }
    : {
      boxSizing: 'border-box',
      marginLeft: `${formats[props.size]}rem`,
      marginRight: `${formats[props.size]}rem`,
      display: 'inline-block',
    }));

export const DrawerHandle = styled.button`
  position: fixed;
  top: 72px;
  left: 0;
  width: 41px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #fff;
  -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
  border: 1px solid #e8e8e8;
`;

export const CategoryDot = styled.div(props => ({
  margin: 'auto',
  height: '8px',
  width: '8px',
  borderRadius: '8px',
  backgroundColor: props.color || 'gray',
}));

export const BrowseLink = styled.button`
  color: ${props => props.color || 'white'};
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    color: #e1e8ff;
  }
`;

export const Align = styled.div`
  text-align: ${props => props.align || 'left'};
`;
