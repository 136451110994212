/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import { jsx, css } from "@emotion/core";
import { Alert, Affix } from "@chainalysis/react-ui";
import { useSelection, useTimeout, usePrevious } from "../utils/hooks";

const containerCss = css`
  position: absolute;
  display: flex;
  width: 100%;
`;

const notificationCss = css`
  color: #595959;
  background: #f4f7ff;
  margin: auto;
`;

const defaultTimeout = 3000;

const TimedNotification = () => {
  const [timedOut, setTimedOut] = React.useState(false);
  const {
    selection: { notification },
  } = useSelection();
  const lastNotification = usePrevious(notification);

  if (timedOut && notification !== lastNotification) {
    setTimedOut(false);
  }

  useTimeout(() => {
    if (notification) {
      setTimedOut(true);
    }
  }, defaultTimeout);

  return !timedOut && notification ? (
    <Affix offsetTop={16}>
      <div css={containerCss}>
        <Alert css={notificationCss} {...notification} />
      </div>
    </Affix>
  ) : null;
};

export default TimedNotification;
