import React from 'react';
import PropTypes from 'prop-types';
import { organizationAccountTypeMap, simpleReducer } from 'utils';
import { getUserInfo, ssoRequest } from 'utils/request';
import getCapabilities from 'utils/capabilities';
import bootZendesk from 'utils/bootZendesk';

const GlobalContext = React.createContext({});
const { Consumer } = GlobalContext;

const GlobalContextProvider = ({ children }) => {
  const [{
    isAuthenticated,
    isAgreementSigned,
    hasKryptosAccess,
    isFirstRender,
    fullName,
    email,
    orgName,
    capabilities,
    isBasicAccount,
    idpUrl,
    isCheckingAuth
  }, setState] = React.useReducer(simpleReducer, {
    isAuthenticated: false,
    isAgreementSigned: false,
    hasKryptosAccess: false,
    isFirstRender: true,
    fullName: null,
    email: null,
    orgName: null,
    capabilities: [],
    isBasicAccount: false,
    idpUrl: null,
    isCheckingAuth: true,
  });

  const checkAuth = async (skipStateUpdate) => {
    setState({ isCheckingAuth: true });
    try {
      const response = await getUserInfo();
      const accountType = response.organizations[0].internalType;
      const newCapabilities = await getCapabilities();
      if (!skipStateUpdate) {
        setState({
          isAuthenticated: true,
          isAgreementSigned: await checkIfAgreementIsSigned(accountType),
          hasKryptosAccess: newCapabilities.includes('INDUSTRY_EXPLORER') || newCapabilities.includes('KRYPTOS_BASICS'),
          isFirstRender: false,
          fullName: response.fullName,
          email: response.userName,
          orgName: response.organizations[0].enterpriseName,
          idpUrl: response.organizations[0].idpUrl,
          isBasicAccount: newCapabilities.includes('KRYPTOS_BASICS')
        });
      }
      setState({ isCheckingAuth: false });
      return response;
    } catch (error) {
      setState({ isCheckingAuth: false, isAuthenticated: false, isFirstRender: false });
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    const fetchContextData = async () => {
      try {
        const userInfo = await checkAuth(true);
        const newCapabilities = await getCapabilities();
        bootZendesk(newCapabilities?.includes('CHAT_SUPPORT'), newCapabilities?.includes('PHONE_SUPPORT'))
        setState({
          isAuthenticated: true,
          isAgreementSigned: await checkIfAgreementIsSigned(userInfo.organizations[0].internalType),
          hasKryptosAccess: newCapabilities.includes('INDUSTRY_EXPLORER') || newCapabilities.includes('KRYPTOS_BASICS'),
          isFirstRender: false,
          fullName: userInfo.fullName,
          email: userInfo.userName,
          orgName: userInfo.organizations ? userInfo.organizations[0].enterpriseName : undefined,
          capabilities: newCapabilities,
          isBasicAccount: newCapabilities.includes('KRYPTOS_BASICS'),
          idpUrl: userInfo.organizations ? userInfo.organizations[0].idpUrl : null,
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchContextData();

    // event listener to listen to local starage change event
    window.addEventListener('storage', localStorageUpdated);

    // cleanup callback function
    return () => {
      window.removeEventListener('storage', localStorageUpdated);
    }
  }, []);

  // method to handle /login redirection on multi tab logout at once
  const localStorageUpdated = () => {
    // set authentication to false if UM token and OIDC access token are not present in the local storage
    if (!isCheckingAuth && !localStorage.getItem('UMToken') && !localStorage.getItem('accessToken')) {
      setState({ isAuthenticated: false, isFirstRender: false });
    }
  }

  const checkIfAgreementIsSigned = (accountType) => {
    let pactsafeGroupKey;
    if (accountType === organizationAccountTypeMap.TRIAL) {
      pactsafeGroupKey = process.env.REACT_APP_PACTSAFE_TRIAL_GROUP_KEY;
    } else if (accountType === organizationAccountTypeMap.TRAINING) {
      pactsafeGroupKey = process.env.REACT_APP_PACTSAFE_TRAINING_GROUP_KEY;
    }
    if (pactsafeGroupKey) {
      // fetch the pactsafe agreement signing status
      return ssoRequest({
        method: 'GET',
        url: `/api/sso/pactsafe/contracts/${pactsafeGroupKey}/currentuser/signingstatus`,
      })
        .then(res => {
          // check if all the contracts are signed
          const allContractsSigned = Object.values(res.data.status).every(val => val);
          return allContractsSigned;
        })
        .catch(() => {
          // continue the flow assuming all the contracts are signed
          return true;
        });
    }
    // continue the flow if account type is not Trial or Trainee
    return Promise.resolve(true);
  };

  const setIsAgreementSigned = (val) => {
    setState({ isAgreementSigned: val });
  };

  const { Provider } = GlobalContext;

  return (
    <Provider value={{
      isAuthenticated,
      isAgreementSigned,
      setIsAgreementSigned,
      hasKryptosAccess,
      fullName,
      email,
      orgName,
      checkAuth,
      isFirstRender,
      capabilities,
      isBasicAccount,
      isCheckingAuth
    }}
    >{children}
    </Provider>
  );
};

GlobalContextProvider.propTypes = { children: PropTypes.node.isRequired };

export { GlobalContextProvider, Consumer as GlobalContextConsumer, GlobalContext };
