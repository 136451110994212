import React from 'react';
import PropTypes from 'prop-types';
import { message, Icon, Button } from '@chainalysis/react-ui';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const iconCss = css`
  color: #293972;
`;

const refreshButtonCss = css`
  display: inline;
  line-height: normal;
  padding: 0;
`;

const ServiceProfileContext = React.createContext({
  isWatchedService: false,
  updateWatchedService: () => false,
});

message.config({ maxCount: 1, duration: 6 });

const ServiceProfileContextProvider = ({ profileData, children }) => {
  const [isWatchedService, setIsWatchedService] = React.useState(false);

  React.useEffect(() => {
    if (profileData.data) {
      setIsWatchedService(profileData.data.isWatchedService);
    }
  }, [profileData.data, setIsWatchedService]);

  const updateWatchedService = (serviceName, orgName, isWatched, isNotify = false) => {
    setIsWatchedService(isWatched);
    if (isNotify) {
      message.open({
        content: isWatched
          ? (
            <div>
              <div>
                <Icon type="eye" theme="filled" css={iconCss} />
                <strong>Service Watched</strong>
              </div>
              <div>
                <span>{serviceName || orgName}&apos;s Alerts will be added to the Alerts page.</span>
              </div>
              <div>
                <Button size="small" css={refreshButtonCss} type="link" onClick={() => window.location.reload()}>
                    Refresh
                </Button> to see current alerts.
              </div>
            </div>
          )
          : (
            <div>
              <Icon type="eye" theme="outlined" css={iconCss} />
              <strong>Service Unwatched</strong>
              <div>
                {serviceName || orgName}&apos;s Alerts will be removed from the Alerts page.
              </div>
            </div>
          ),
        key: 'watchedServiceMessage',

      });
    }
  };

  return (
    <ServiceProfileContext.Provider
      value={{ isWatchedService, updateWatchedService }}
    >{children}
    </ServiceProfileContext.Provider>
  );
};

ServiceProfileContextProvider.propTypes = {
  profileData: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
};

export {
  ServiceProfileContext,
  ServiceProfileContextProvider,
};
