import React, { useEffect, useReducer } from 'react';
import { Typography, Tooltip, Button, Loader, Panel, Radio } from '@chainalysis/react-ui';
/** @jsx jsx @jsxFrag React.Fragment @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TransferVolumeSeriesChart, BenchmarkSeriesChart } from '.';
import { getChartConfig, simpleReducer } from '../utils';
import request from '../utils/request';
import { CustomSet } from '../utils/customPropTypes';
import { Spacer, Align } from './styled/ui';
import { PanelHeader, PanelLink, InfoIcon } from './styled/text';
import { LoadingContext } from './providers/LoadingContextProvider';
import { UiStateConsumer } from './providers/UiStateProvider';

const ResolutionToolbar = ({ resolution, onResolutionChange, disabled }) => (
  <Align align="right">
    <Radio.Group
      disabled={disabled}
      value={resolution}
      buttonStyle="solid"
      onChange={e => onResolutionChange(e.target.value)}
    >
      <Radio.Button value="DAY">Day</Radio.Button>
      <Radio.Button value="WEEK">Week</Radio.Button>
    </Radio.Group>
  </Align>
);

ResolutionToolbar.propTypes = {
  resolution: PropTypes.string.isRequired,
  onResolutionChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ResolutionToolbar.defaultProps = { disabled: false, onResolutionChange: null };

// -16px Removes ant-card-title padding
const tabItemWrapperCss = css`
  height: 43px;
  padding: 0 1rem;
  margin-bottom: -1rem;
  display: inline-block;
`;
const activeTabItemWrapperCss = css`
  ${tabItemWrapperCss};
  border-bottom: 3px solid rgb(84,95,147);
`;

const chartTypes = ['topVolume', 'benchmark'];
// TODO: Cleanup this double range calculation
const defaultDateRange = [moment().subtract(3, 'years').unix(), moment().unix()];

const TimeSeriesPanel = ({
  asset,
  address,
  startDate,
  endDate,
  categories,
  allCategories,
  resolution,
  serviceName,
  isBTCService,
  fixedChartType,
  onFilterUpdate,
}) => {
  const { isLoading } = React.useContext(LoadingContext);
  const [
    { loading, error, chartData, tryAgain, chartType },
    setState,
  ] = useReducer(simpleReducer, {
    loading: false,
    error: false,
    chartData: {},
    tryAgain: false,
    chartType: fixedChartType || 'topVolume',
  });

  React.useLayoutEffect(() => {
    setState({ loading: isLoading });
  }, [isLoading]);

  const adjustedStartDate = startDate || defaultDateRange[0];
  const adjustedEndDate = endDate || defaultDateRange[1];

  const fixedChartTypeConfig = fixedChartType
    ? getChartConfig(fixedChartType, {
      asset,
      address,
      resolution,
      startDate: adjustedStartDate,
      endDate: adjustedEndDate,
      serviceName,
      allCategories,
      categories,
    })
    : {};

  const chartConfigs = chartTypes.map(type => getChartConfig(type, {
    asset,
    address,
    resolution,
    startDate: adjustedStartDate,
    endDate: adjustedEndDate,
    serviceName,
    allCategories,
    categories,
  }));

  const HeaderTabs = React.useMemo(() => (
    <>
      {chartConfigs.map((chartConfig) => {
        if (chartConfig.chartType === chartType) {
          return (
            <div key={chartConfig.chartType} css={activeTabItemWrapperCss}>
              <PanelHeader>{chartConfig.title}</PanelHeader>
              <Spacer spaceDirection="horizontal" size="small" />
              <Tooltip
                placement="topRight"
                arrowPointAtCenter
                title={chartConfig.tooltip}
              >
                <InfoIcon active />
              </Tooltip>
            </div>
          );
        }
        return (
          <div key={chartConfig.chartType} css={tabItemWrapperCss}>
            <PanelLink
              data-test-id={chartConfig.title}
              onClick={() => setState({
                chartType: chartConfig.chartType,
                chartData: {},
              })}
            >{chartConfig.title}
            </PanelLink>
            <Spacer spaceDirection="horizontal" size="small" />
            <Tooltip
              placement="topRight"
              arrowPointAtCenter
              title={chartConfig.tooltip}
            >
              <InfoIcon />
            </Tooltip>
          </div>);
      })}
    </>
  ), [chartType, chartConfigs]);

  const categoriesSize = categories?.size;
  const currentChartConfig = chartConfigs.find(c => c.chartType === chartType);
  const shouldSkipRequest = isLoading
  || (!categories || categories.size === 0)
  || (chartType === 'benchmark' && allCategories);

  const fetchData = async () => {
    setState({ loading: true, error: false });

    if (shouldSkipRequest) {
      return setState({ loading: false, error: false, chartData: {} });
    }

    let result = {};
    try {
      result = await request({
        method: 'GET',
        url: currentChartConfig.url,
      });
      const apiResponse = {
        chartData: currentChartConfig.responseMapper(result),
        loading: false,
      };
      return setState({ ...apiResponse });
    } catch (err) {
      return setState({ error: true, loading: false });
    }
  };

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [tryAgain, chartType, asset, startDate, endDate, categoriesSize, allCategories, resolution]);

  const tryAgainSelect = () => {
    setState({ tryAgain: !tryAgain });
  };

  return (
    <Panel title={fixedChartType
      ? (
        <>
          <PanelHeader>{fixedChartTypeConfig.title}</PanelHeader>
          <Spacer spaceDirection="horizontal" size="small" />
          <Tooltip
            placement="topRight"
            arrowPointAtCenter
            title={fixedChartTypeConfig.tooltip}
          >
            <InfoIcon active />
          </Tooltip>
        </>
      )
      : HeaderTabs}
    >
      <UiStateConsumer>
        {({ sidebarCollapsed }) => (error ? (
          <div css={{ padding: '8.5rem 7rem' }}>
            <Typography.Paragraph strong css={{ textAlign: 'center' }}>
              Sorry, something went wrong. <Button onClick={() => tryAgainSelect()}>Try Again</Button>
            </Typography.Paragraph>
          </div>
        ) : loading ? (
          <>
            {chartType === 'topVolume' && <ResolutionToolbar disabled resolution={resolution} />}
            <div css={{ padding: '8.5rem 7rem' }}>
              <Loader description="Generating Chart" />
            </div>
          </>
        ) : (
          <>
            {chartType === 'topVolume' ? (
              <>
                <ResolutionToolbar
                  resolution={resolution}
                  onResolutionChange={r => onFilterUpdate({ resolution: r })}
                />
                <Spacer spaceDirection="vertical" size="medium" />
                <TransferVolumeSeriesChart
                  wideChart={sidebarCollapsed}
                  noDataMessage="There is no data for either the categories, asset, or date range selected."
                  resolution={resolution}
                  data={chartData}
                  selected={categories || new Set()}
                />
              </>
            ) : (
              <>
                <BenchmarkSeriesChart
                  isBTCService={isBTCService}
                  wideChart={sidebarCollapsed}
                  noDataMessage="No transfer data is available for your selected filters."
                  serviceName={serviceName}
                  data={chartData}
                  allSelected={allCategories}
                  selected={categories || new Set()}
                />
              </>
            )}
          </>
        ))
            }
      </UiStateConsumer>
    </Panel>
  );
};

TimeSeriesPanel.propTypes = {
  asset: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  categories: CustomSet,
  allCategories: PropTypes.bool,
  resolution: PropTypes.string.isRequired,
  serviceName: PropTypes.string,
  isBTCService: PropTypes.bool.isRequired,
  fixedChartType: PropTypes.string,
  onFilterUpdate: PropTypes.func.isRequired,
};

TimeSeriesPanel.defaultProps = {
  startDate: null,
  endDate: null,
  categories: null,
  allCategories: false,
  fixedChartType: null,
  serviceName: 'N/A',
};

export default TimeSeriesPanel;
