import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DatePicker } from '@chainalysis/react-ui';
import { Spacer } from './styled/ui';

const aYear = moment.duration({ years: 1 });

const DateRangePicker = ({ onChange, defaultStartDate, defaultEndDate, className }) => {
  // Start and End dates are moment() instances
  const [startDate, setStartDate] = React.useState(
    moment.utc(defaultStartDate * 1000)
  );
  const [endDate, setEndDate] = React.useState(
    moment.utc(defaultEndDate * 1000)
  );
  const [endOpen, setEndOpen] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);

  // Make pristine once updated external dates
  React.useEffect(() => {
    setDirty(false);
  }, [defaultStartDate, defaultEndDate]);

  React.useEffect(() => {
    if (dirty && Boolean(startDate) === Boolean(endDate)) {
      onChange([
        startDate ? startDate.unix() : null,
        endDate ? endDate.unix() : null,
      ]);
    }
  }, [startDate, endDate, dirty, onChange]);

  const disabledStartDate = startValue => (startValue && endDate) && startValue > endDate;

  const disabledEndDate = endValue => (startDate && endValue)
  && (endValue < startDate);

  // Once start date is selected, on closing the start date picker set the end date to null,
  // causing the end date picker to use the default picker value, which is set to selected start date.
  // This way it is easier to pick values that are close to each other
  const onOpenChange = isEnd => (open) => {
    if (isEnd) {
      setEndOpen(open);
    } else {
      if (!open) {
        setEndDate(null);
      }
      setEndOpen(!open);
    }
  };

  return (
    <>
      <DatePicker
        className={className}
        disabledDate={disabledStartDate}
        format="ll"
        defaultPickerValue={startDate}
        value={startDate}
        placeholder="Start Date"
        onChange={(date) => { setDirty(true); setStartDate(date); }}
      />
      <Spacer spaceDirection="horizontal" size="small" />
      <DatePicker
        className={className}
        disabledDate={disabledEndDate}
        format="ll"
        defaultPickerValue={endDate}
        value={endDate}
        placeholder="End Date"
        onChange={(date) => { setDirty(true); setEndDate(date); }}
      />
    </>
  );
};

DateRangePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultStartDate: PropTypes.number.isRequired,
  defaultEndDate: PropTypes.number.isRequired,
};

DateRangePicker.defaultProps = { className: undefined };

export default DateRangePicker;
