import React from 'react';
import ReactDOM from 'react-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import './index.css';
import App from './components/App';
import { unregister } from './registerServiceWorker';

/** Initialize DataDog logs */
if (window && window.runtimeConfig && window.runtimeConfig.reactorUrl === 'https://reactor.chainalysis.com/') {
  datadogLogs.init({
    clientToken: 'pub7c97c09edf07399061a619f922d525a8',
    forwardErrorsToLogs: true,
  });
}
/**
 * Initialize DataDog Realtime User Monitoring
 */
datadogRum.init({
  clientToken: 'pub6f752549129fc5d839e6e01fcd4557ed',
  applicationId: '39f12514-de81-41b4-8cce-acd5a9dbe63c',
});

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
