/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import debounce from "lodash/debounce";
import { withRouter } from "react-router-dom";
import { AutoComplete, Icon, Input, Typography } from "@chainalysis/react-ui";
import { jsx } from "@emotion/core";
import { getCategoryText } from "../utils";
import request from "../utils/request";

const { Option } = AutoComplete;

const searchResultItem = (result) => (
  <Option key={result.service}>
    <Typography.Text>{result.service}</Typography.Text>
    <Typography.Text
      type="secondary"
      css={{ float: "right", fontSize: ".75rem" }}
    >
      {getCategoryText(result.category) || "None"}
    </Typography.Text>
  </Option>
);

class SearchFilter extends React.PureComponent {
  static propTypes = { history: ReactRouterPropTypes.history.isRequired };

  constructor(props) {
    super(props);
    this.searchByUserId = debounce(this.searchByUserId, 400);
  }

  state = {
    isLoading: false,
    results: [],
    value: "",
    isError: false,
  };

  searchByUserId = () =>
    this.state.value.length > 0 &&
    request({
      method: "GET",
      url: `/api/services/?service_prefix=${this.state.value}`,
    })
      .then((response) =>
        this.setState({
          isLoading: false,
          isError: false,
          results: response.data,
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
          isError: true,
        })
      );

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" });

  handleResultSelect = (result) => {
    this.props.handleFilterChange(result, this.props.filterName);
    this.setState({ value: "", results: [] });
  };

  handleSearchChange = (value) => {
    if (value.length < 1) {
      return this.resetComponent();
    }
    return this.setState({ isLoading: true, value }, () =>
      this.searchByUserId()
    );
  };

  render() {
    const { isLoading, results, value, isError } = this.state;
    const children = isError
      ? [<Option key={1}>Search is unavailable</Option>]
      : value.length > 0 &&
        results.length === 0 &&
        value !== this.props.selected &&
        !isLoading
      ? [<Option key={1}>No results found</Option>]
      : results.map(searchResultItem);
    return (
      <AutoComplete
        loading={isLoading}
        onSelect={
          !isLoading && results && results.length
            ? this.handleResultSelect
            : undefined
        }
        onSearch={this.handleSearchChange}
        dataSource={children}
        value={value}
        optionLabelProp="value"
        dropdownStyle={{ width: 300 }}
      >
        <Input
          css={{ "@media (min-width: 991px)": { minWidth: 325 } }}
          placeholder="Search service name"
          prefix={<Icon type={isLoading ? "loading" : "search"} />}
        />
      </AutoComplete>
    );
  }
}

export default withRouter(SearchFilter);
