/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { parseLocalStorageItem } from 'utils';
import Auth0OIDCAuthentication from './auth0OIDCAuthentication';

const { runtimeConfig } = window;
// rxjs subjects to observe OIDC access token renewal status
const accessTokenRenewalInProgressSubject = new BehaviorSubject(false);
// rxjs subject to observe UM token renewal status
const UMTokenRenewalInProgressSubject = new BehaviorSubject(false);
// maximum retry count when request failed with 403 status
const MAX_REQUEST_RETRIES = 6;

const client = axios.create({
  baseURL: '/',
  headers: { 'Content-Type': 'application/json' },
});

const SSO = axios.create({
  baseURL: runtimeConfig.ssoServer,
  headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
  withCredentials: true,
});

/**
 * Extract bearer token from the request Authorization header
 * @param req
 */
const parseBearerToken = (req) => {
  const auth = req.headers ? req.headers.Authorization || null : null;
  if (!auth) {
    return null;
  }

  const parts = auth.trim().split(/\s+/);
  // Malformed header
  if (parts.length < 2) {
    return null;
  }

  const schema = (parts.shift()).toString().toLocaleLowerCase();
  const token = parts.join(' ');
  if (schema !== 'bearer') {
    return null;
  }

  return token;
}

const getToken = () =>
  SSO.get('/api/sso/auth/userinfo')
    .then(response => {
      localStorage.setItem('tokenLogout', response.data.csrfToken);
      if (response.data.organizations[0].idpUrl) {
        localStorage.setItem('idpUrl', response.data.organizations[0].idpUrl);
      }
      return SSO.post('/api/sso/auth/token', {
        orgId: response.data.organizations[0].id,
        productName: 'Reactor',
      });
    }).then(response => {
      const { data: { token, capabilities, expirationDate } } = response;
      localStorage.setItem('UMToken', token);
      localStorage.setItem('tokenExpiration', String(expirationDate));
      localStorage.setItem('capabilities', JSON.stringify(capabilities));
      client.defaults.headers.Token = token;
      return Promise.resolve(capabilities);
    })

const refreshAccessToken = refreshToken =>
  SSO.get(`/api/sso/auth/token/refreshoidctoken`, { params: { refresh_token: refreshToken } })
    .then(response => {
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return Promise.resolve(response.data);
    })

const getUserInfo = () =>
  SSO.get('/api/sso/auth/userinfo')
    .then(response => Promise.resolve(response.data))

// legacy(username-password) login
const login = (email, password) =>
  SSO.post('/api/sso/auth/login', { user: email, password })
    .then(response => {
      clearUserSessionInfoLocalStorage();
      localStorage.setItem('isIDPLogin', false);
      localStorage.setItem('tokenLogout', response.data.token);
      return Promise.resolve(response.data);
    })

// login with authorization code and id token after SAML login redirection from IDP via Auth0
const auth0Login = (authorizationCode, idToken) =>
  SSO.post(`/api/sso/auth/auth0_login`, null, {
    params: { authorizationCode, idToken },
  }).then(response => {
    clearUserSessionInfoLocalStorage();
    localStorage.setItem('isIDPLoginViaSAML', true);
    localStorage.setItem('tokenLogout', response.data.token);
    return Promise.resolve(response.data);
  })

// login with authorization code, state after OIDC redirection login from IDP
const oidcLogin = (authorizationCode, state, redirectUrl) =>
  SSO.get(`/api/sso/auth/token/oidctoken`, {
    params: {
      authorization_code: authorizationCode,
      state,
      redirectUrl,
    }
  }).then(response => {
    clearUserSessionInfoLocalStorage();
    localStorage.setItem('isIDPLogin', true);
    localStorage.setItem('tokenLogout', response.data.token);
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    return Promise.resolve(response.data);
  })

const clearUserSessionInfoLocalStorage = () => {
  localStorage.removeItem('tokenExpiration');
  localStorage.removeItem('capabilities');
  localStorage.removeItem('isIDPLogin');
  localStorage.removeItem('isIDPLoginViaSAML');
  localStorage.removeItem('idpUrl');
  localStorage.removeItem('tokenLogout');
  localStorage.removeItem('UMToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

const legacySignOut = () =>
  SSO.request({
    method: 'POST',
    url: '/api/sso/auth/logout',
    headers: { 'x-csrf-token': localStorage.getItem('tokenLogout') },
  }).finally(() => {
    const idpUrl = localStorage.getItem('idpUrl');
    const isIDPLogin = parseLocalStorageItem('isIDPLogin')
    const isIDPLoginViaSAML = parseLocalStorageItem('isIDPLoginViaSAML')
    clearUserSessionInfoLocalStorage();

    if (isIDPLogin || isIDPLoginViaSAML) {
      const auth0OIDCAuthentication = new Auth0OIDCAuthentication();
      const loginUrl = process.env.REACT_APP_LOGIN_URL || `${window.location.origin}/login`;
      const returnTo = (isIDPLoginViaSAML && idpUrl) ? idpUrl : loginUrl;
      auth0OIDCAuthentication.logout(returnTo);
    } else {
      window.location.href = process.env.REACT_APP_LOGIN_URL || '/login';
    }
  });

const signOut = () => {
  if (!process.env.REACT_APP_LOGOUT_URL || process.env.REACT_APP_LOGOUT_URL === '/login') {
    legacySignOut();
  } else {
    clearUserSessionInfoLocalStorage();
    window.location.href = process.env.REACT_APP_LOGOUT_URL
  }
}

SSO.interceptors.response.use(response => response, async (error) => {
  if (error.response && error.response.status === 401 && !window.location.pathname.includes('login') && !window.location.pathname.includes('password')) {
    await signOut();
  }
  return Promise.reject(error);
});

const kryptosApiResponseErrorInterceptor = async error => {
  const { response, config: originalRequest } = error;

  if (response.status === 401 && !window.location.pathname.includes('login') && !window.location.pathname.includes('password')) {
    await signOut();
  } else if (
    (response && response.status === 403) ||
    (response && response.data.messages && response.data.messages[0].token_class === 'AccessToken')
  ) {
    const originalReqAccessToken = parseBearerToken(originalRequest);

    // do not replay request if it has already been replayed equal to the `MAX_REQUEST_RETRIES`
    originalRequest.retry_count = typeof originalRequest.retry_count === 'undefined' ? 0 : ++originalRequest.retry_count;
    if (originalRequest.retry_count === MAX_REQUEST_RETRIES) {
      return Promise.reject(error);
    }

    // req failed with the OIDC access token (JWT)
    if (originalReqAccessToken) {
      // make an refresh OIDC access token API call if it is not already in progress
      if (!accessTokenRenewalInProgressSubject.value) {
        accessTokenRenewalInProgressSubject.next(true);
        const oidcRefreshToken = localStorage.getItem('refreshToken');
        return refreshAccessToken(oidcRefreshToken).then(() => {
          return getToken();
        }).then(() => {
          accessTokenRenewalInProgressSubject.next(false);
          // NOTE: since APIs are not fully OIDC jwt compliant user management token is also passed
          originalRequest.headers.Token = localStorage.getItem('UMToken');
          // OIDC Access Token is only available in case of OIDC login
          originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
          return client.request(originalRequest)
        }).catch(async () => {
          accessTokenRenewalInProgressSubject.next(false);
          await signOut();
        })
      }
      // wait until new access token is fetched and then replay all the failed requests with a new OIDC access token
      return accessTokenRenewalInProgressSubject.pipe(
        filter((renewalInProgress) => !renewalInProgress),
        take(1),
        switchMap(() => {
          // NOTE: since APIs are not fully OIDC jwt compliant user management token is also passed
          originalRequest.headers.Token = localStorage.getItem('UMToken');
          // OIDC Access Token is only available in case of OIDC login
          originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
          return client.request(originalRequest);
        }),
      ).toPromise();
    }

    // make an API call to get a new UM token if it is not already in progress
    if (!UMTokenRenewalInProgressSubject.value) {
      UMTokenRenewalInProgressSubject.next(true);
      return getToken().then(() => {
        UMTokenRenewalInProgressSubject.next(false);
        originalRequest.headers.Token = localStorage.getItem('UMToken');
        return client.request(originalRequest);
      }).catch(async () => {
        UMTokenRenewalInProgressSubject.next(false);
        await signOut();
      })
    }
    // wait until new UM token is fetched and then replay all the failed requests with a new UM token
    return UMTokenRenewalInProgressSubject.pipe(
      filter((renewalInProgress) => !renewalInProgress),
      take(1),
      switchMap(() => {
        originalRequest.headers.Token = localStorage.getItem('UMToken');
        return client.request(originalRequest);
      }),
    ).toPromise();
  }
  return Promise.reject(error);
};

client.interceptors.response.use(
  response => response,
  kryptosApiResponseErrorInterceptor,
);

const request = (options) => {
  const UMToken = localStorage.getItem('UMToken');

  if (UMToken) {
    client.defaults.headers.Token = UMToken;
  }

  // NOTE: we are not adding access token(JWT) to the requests as Kryptos does not fully support JWT based authorization

  const onSuccess = response => response;
  const onError = error => Promise.reject(error.response || error.message);

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

const ssoRequest = options => {
  const UMToken = localStorage.getItem('UMToken');
  const axiosOptions = options;

  // NOTE: token is not set to default headers because /userinfo and /token endpoints
  // failes with 403 when getting new token after UM token expires
  if (UMToken) {
    let { headers } = axiosOptions;
    headers = headers ? { ...headers, Token: UMToken } : { Token: UMToken };
    axiosOptions.headers = headers;
  }

  const onSuccess = response => response;
  const onError = error => Promise.reject(error.response || error.message);

  return SSO(axiosOptions)
    .then(onSuccess)
    .catch(onError);
};

export { login, signOut, getToken, auth0Login, oidcLogin, getUserInfo, ssoRequest };

export default request;
