import auth0 from 'auth0-js';

export default class Auth0OIDCAuthentication {
  constructor() {
    this.domain = process.env.REACT_APP_OIDC_DOMAIN || '';
    this.clientID = process.env.REACT_APP_OIDC_CLIENT_ID || '';
    this.redirectUrl = (window.location.origin + process.env.REACT_APP_OIDC_REDIRECT_PATH) || '';
    this.apiIdentifier = process.env.REACT_APP_OIDC_MGMT_API_IDENTIFIER || '';
    this.webAuth = new auth0.WebAuth({
      domain: this.domain,
      clientID: this.clientID,
    })
  }

  login = connection => {
    this.webAuth.authorize({
      connection,
      responseType: 'code',
      scope: 'openid email offline_access',
      audience: this.apiIdentifier,
      redirectUri: this.redirectUrl,
    });
  }

  logout = returnTo => {
    this.webAuth.logout({
      returnTo,
      clientID: this.clientID,
    });
  }
}
