import React from 'react';
import PropTypes from 'prop-types';

function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

const UiContext = React.createContext({
  sidebarCollapsed: false,
  setUiState: () => {},
});

const { Provider, Consumer } = UiContext;

const UiStateProvider = ({ children }) => {
  const [{ sidebarCollapsed }, setUiState] = React.useReducer(reducer, { sidebarCollapsed: false });
  return (
    <Provider value={{ sidebarCollapsed, setUiState }}>{children}</Provider>
  );
};


UiStateProvider.propTypes = { children: PropTypes.node.isRequired };

export {
  UiContext,
  Consumer as UiStateConsumer,
  UiStateProvider,
};
