/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import PropTypes from "prop-types";
import { Button, Menu, Checkbox, Typography } from "@chainalysis/react-ui";
import { jsx } from "@emotion/core";
import { SearchFilter } from ".";
import { Spacer } from "./styled/ui";
import { MultiSelectItem } from "./styled";
import { primaryColor } from "../utils/constants";

const FilterMenu = ({
  handleFilterChange,
  filterName,
  queryParams,
  filters,
  toggleDirectOnly,
  isDirectOnly,
  handleFilterOption,
  name,
}) => {
  const isCategory =
    name === "transfers" && filterName.toLowerCase().includes("category");
  const isService =
    name === "transfers" && filterName.toLowerCase().includes("service");
  const categories = queryParams[filterName]
    ? queryParams[filterName].split(",")
    : [];
  return (
    <Menu
      css={{ maxHeight: "25rem", minWidth: "12rem", overflowY: "scroll" }}
      onSelect={
        isService
          ? undefined
          : (item) => handleFilterChange(item.key, filterName)
      }
      selectedKeys={[queryParams[filterName]]}
    >
      <div
        css={{
          padding: "8px 16px",
          color: "rgba(0, 0, 0, 0.45)",
          fontSize: "14px",
          lineHeight: 1.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Filters
        {queryParams[filterName] ? (
          <Button
            css={{ border: 0, color: primaryColor, padding: 0 }}
            onClick={() => handleFilterChange(null, filterName)}
          >
            Reset
          </Button>
        ) : (
          <span
            css={{ width: "36px", height: "32px", display: "inline-block" }}
          />
        )}
      </div>
      <Menu.Divider />
      {(isCategory || isService) && (
        <div
          css={{
            height: "40px",
            lineHeight: "40px",
            marginTop: "4px",
            marginBottom: "4px",
            padding: "0 16px",
            overflow: "hidden",
            fontSize: "14px",
            textOverflow: "ellipsis",
          }}
        >
          <Checkbox
            onChange={(e) => {
              toggleDirectOnly(e.target.checked);
              handleFilterOption(filterName);
            }}
            checked={isDirectOnly}
          >
            Direct Only
          </Checkbox>
        </div>
      )}
      {(isCategory || isService) && <Menu.Divider />}
      {isService && (
        <div css={{ padding: "1rem" }}>
          {queryParams[filterName] && (
            <>
              <Typography.Text css={{ lineHeight: 1.25 }}>
                Filtered by “{queryParams[filterName]}”
              </Typography.Text>
              <Spacer spaceDirection="vertical" size="medium" />
            </>
          )}
          <SearchFilter
            selected={queryParams[filterName]}
            handleFilterChange={handleFilterChange}
            filterName={filterName}
          />
        </div>
      )}
      {!(isCategory || isService) &&
        filters.map((filter) => (
          <Menu.Item key={filter.key} value={filter.value}>
            {filter.text}
          </Menu.Item>
        ))}
      {isCategory &&
        filters.map((filter) => (
          <MultiSelectItem key={filter.key}>
            <Checkbox
              checked={
                queryParams[filterName]
                  ? queryParams[filterName].split(",").includes(filter.key)
                  : false
              }
              onChange={(e) =>
                e.target.checked
                  ? handleFilterChange(
                      [...categories, e.target.value].join(","),
                      filterName
                    )
                  : handleFilterChange(
                      categories
                        .filter((category) => category !== e.target.value)
                        .join(","),
                      filterName
                    )
              }
              value={filter.value}
            >
              {filter.text}
            </Checkbox>
          </MultiSelectItem>
        ))}
    </Menu>
  );
};

FilterMenu.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  queryParams: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleDirectOnly: PropTypes.func,
  isDirectOnly: PropTypes.bool,
  filterName: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterOption: PropTypes.func,
  name: PropTypes.string.isRequired,
};

FilterMenu.defaultProps = {
  toggleDirectOnly: undefined,
  handleFilterOption: undefined,
  isDirectOnly: false,
};

export default FilterMenu;
