/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from "react";
import { Select, Row, Col } from "@chainalysis/react-ui";
import PropTypes from "prop-types";
import { jsx, css } from "@emotion/core";
import { SectionHeader } from "./styled/text";
import { Spacer } from "./styled/ui";
import { mq } from "../utils";
import { CustomSet } from "../utils/customPropTypes";
import { MultiFilterSelect } from ".";
import { LoadingContext } from "./providers/LoadingContextProvider";
import { SelectionContext } from "./providers/SelectionContextProvider";
import DateRangeFilter from "./DateRangeFilter";

const filterLabelCss = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  letter-spacing: 0.36px;
  color: #556372;
  text-transform: uppercase;
  ${mq("xl")} {
    display: none;
  }
`;

const filterOverrideCss = css`
  background: #f4f7ff;
  border: 1px solid #002766;
  box-sizing: border-box;
  border-radius: 4px;

  [role="combobox"] {
    background: #f4f7ff;
  }

  input {
    background: #f4f7ff;
  }
`;

const TransfersToolbar = ({
  asset,
  categories,
  assetOptions,
  categoryOptions,
  onFilterUpdate,
  startDate,
  endDate,
  filterOverride,
  navigationId,
  headerOnly,
}) => {
  const { isLoading } = React.useContext(LoadingContext);
  const { updateSelection } = React.useContext(SelectionContext);
  const data = categoryOptions;

  const updateFilter = (filter) => {
    onFilterUpdate(filter);
    updateSelection({ selectedAlert: null });
  };

  const selectFilters = (category) => {
    if (categories !== null) {
      switch (category) {
        case "all":
          return updateFilter({
            filterCategories: new Set(data.map((c) => c.value)),
          });
        case "none":
          return updateFilter({ filterCategories: new Set() });
        case "risky":
          return updateFilter({
            filterCategories: new Set(
              data.filter((c) => c.isRisky).map((c) => c.value)
            ),
          });
        default:
          if (categories.has(category)) {
            const filteredSelected = new Set([...categories]);
            filteredSelected.delete(category);
            updateFilter({ filterCategories: filteredSelected });
          } else {
            const filteredSelected = new Set([...categories, category]);
            updateFilter({ filterCategories: filteredSelected });
          }
      }
    }
    return null;
  };

  return (
    <Row type="flex" justify="space-between" gutter={[16, 16]}>
      {headerOnly || (
        <Col>
          <SectionHeader
            type="area-chart"
            text="Transfer Data"
            id={navigationId}
          />
        </Col>
      )}
      <Col>
        <span css={filterLabelCss}>filter by</span>
        <Spacer spaceDirection="horizontal" size="small" />
        <MultiFilterSelect
          css={filterOverride ? filterOverrideCss : undefined}
          disabled={isLoading}
          selectedFilters={categories || new Set()}
          options={data || []}
          selectFilters={selectFilters}
        />
        <Spacer spaceDirection="horizontal" size="small" />
        <Select
          css={filterOverride ? filterOverrideCss : undefined}
          style={{ width: 120 }}
          disabled={assetOptions.loading}
          value={asset}
          defaultValue={asset}
          loading={assetOptions.loading}
          onChange={(value) =>
            updateFilter({
              filterAsset: value,
              filterAddress: assetOptions.data.find((a) => a.asset === value)
                .address,
            })
          }
        >
          {assetOptions.data.length &&
            assetOptions.data.map((option) => (
              <Select.Option key={option.asset} value={option.asset}>
                {option.asset}
              </Select.Option>
            ))}
        </Select>
        <Spacer spaceDirection="horizontal" size="small" />
        <span css={filterLabelCss}>
          view data from
          <Spacer spaceDirection="horizontal" size="small" />
        </span>
        <DateRangeFilter
          startDate={startDate}
          endDate={endDate}
          handleDateRangeFilter={([start, end]) =>
            updateFilter({
              filterStartDate: start,
              filterEndDate: end,
            })
          }
        />
      </Col>
    </Row>
  );
};

TransfersToolbar.propTypes = {
  asset: PropTypes.string.isRequired,
  categories: CustomSet,
  categoryOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  assetOptions: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  filterOverride: PropTypes.bool,
  headerOnly: PropTypes.bool,
  navigationId: PropTypes.string,
};

TransfersToolbar.defaultProps = {
  categories: null,
  startDate: undefined,
  endDate: undefined,
  filterOverride: false,
  headerOnly: false,
  navigationId: undefined,
};

export default TransfersToolbar;
